import React, { useState, useContext, useEffect } from "react";
import LayoutCart from "../LayoutCart/LayoutCart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";

import { UserContext } from "../../context/userContextProvider";
import axiosInstance from "../../helpers/axios";

import ApiLoader from "../utils/ApiLoader";
import Addresses from "./Addresses";
import AddressFormModal from "./AddressFormModal";

import cart_shipping_icon from "../assets/images/cart_shipping.png";
import left_link_icon from "../assets/images/left_link.png";

const Checkout = () => {
  const location = useLocation();
  const naviate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [subTotal, setSubTotal] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [shippingType, setShippingType] = useState("");
  const [shippingCharge, setShippingCharge] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddressFormModal, setShowAddressFormModal] = useState(false);
  const [shippingAddress, setShippingAddress] = useState("");
  const [addresses, setAddresses] = useState([]);
  const handleShowAddressForm = () => setShowAddressFormModal(true);
  const handleCloseAddressForm = () => setShowAddressFormModal(false);

  const handleChangeShippingAddress = (value) => setShippingAddress(value);

  const getUserProfile = async () => {
    try {
      const { data } = await axiosInstance.get(`/user/profile`);
      setAddresses(data[0].addresses);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "something went wrong!",
        confirmButtonText: "Okay",
        allowOutsideClick: false,
      });
    }
  };
  // here we are handle our payment links and order process
  const handleOrder = async () => {
    if (shippingType === "") {
      alert("Please Select your shipping method");
      setIsLoading(false);
    } else if (
      !shippingAddress &&
      shippingType !== "Collect from our manufacturer center"
    ) {
      Swal.fire({
        title: "Choose shipping address to continue with the payment",
        icon: "warning",
        timer: 3000,
      });
    } else {
      setIsLoading(true);

      // const data = {
      //   total_cost: location.state.totalPrice + location.state.shippingChrges,
      //   access_token: access_token,
      //   payment_status: "Failed",
      //   shipping_method: shippingType,
      //   payment_method: "CARD",
      //   data: location.state.productDetails,
      //   shipping_address: userDetails.user.address,
      // };
      window.localStorage.setItem("shipping_address", shippingAddress);
      window.localStorage.setItem("payment_method", paymentMethod);
      window.localStorage.setItem("shipping_method", shippingType);
      window.localStorage.setItem("user-id", userDetails.user.user_id);
      window.localStorage.setItem(
        "billing_address",
        userDetails?.user?.billing_address_details?.billing_address
      );
      window.localStorage.setItem(
        "amount",
        shippingType == "Collect from our manufacturer center"
          ? location.state.totalPrice
          : location.state.totalPrice + Number(shippingCharge)
      );
      if (paymentMethod === "cash") {
        naviate("/ThankYou");
      } else {
        const res = await axiosInstance.get("/payment/access-token");
        const access_token = res.data;
        window.localStorage.setItem("payment_access_token", access_token);
        if (shippingType == "Collect from our manufacturer center") {
          const createOrder = await axiosInstance.post(
            "/payment/create-order",
            {
              access_token: access_token,
              price: location.state.totalPrice,
            }
          );
          window.location.replace(createOrder.data);
        } else {
          const createOrder = await axiosInstance.post(
            "/payment/create-order",
            {
              access_token: access_token,
              price: location.state.totalPrice + Number(shippingCharge),
            }
          );
          window.location.replace(createOrder.data);
        }
      }

      setIsLoading(false);
    }
  };
  const getCartItems = async () => {
    const token = localStorage.getItem("order-session");
    const config = {
      headers: {
        CreateOrder: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.get("/cart/all", config);
    const items = response.data;
    setSubTotal(items.subTotal);
    setTotalPrice(items.totalPrice);
  };

  const getShippingCharge = async () => {
    try {
      const { data } = await axiosInstance.get("/percentages/shipping-charge");
      setShippingCharge(data.charges);
    } catch (error) {}
  };
  const handlePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };
  const handleAddAddress = async (address) => {
    try {
      await axiosInstance.post("/address/add", { address: address });
      getUserProfile();
      handleCloseAddressForm();
      Swal.fire({
        title: "Success",
        text: "Addresses added successfully",
        icon: "success",
        confirmButtonText: "Okay",
      });
    } catch (error) {
      Swal.fire({
        title: "Something went wrong",
        text: error,
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };
  useEffect(() => {
    getCartItems();
    getShippingCharge();
    getUserProfile();
  }, []);

  return (
    <LayoutCart>
      <div className="innerpage Rigid_page">
        <div className="container">
          <div className="progress_bar">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: "25%" }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div className="teable_head">
            <div className="teable_head_left">
              <Link to="" className="back_cart">
                <img src={left_link_icon} loading="lazy" alt="" />
              </Link>
              <h3>We need few details about You product Shipping Method</h3>
            </div>
            <div className="teable_head_right"></div>
          </div>

          <div className="shoppingCart_body Checkout">
            <div className="left">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item contact_information">
                  <div className="accordion-item_inner">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Contact Information
                      </button>
                    </h2>
                    {userDetails && (
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <section>
                            <h1 className="checkout-contact-detials-header">
                              Name:{" "}
                              <span>
                                {userDetails.user.first_name}{" "}
                                {userDetails.user.last_name}
                              </span>
                            </h1>
                          </section>
                          <section>
                            <h3 className="checkout-contact-detials-header">
                              Email: <span>{userDetails.user.email}</span>
                            </h3>
                            <h3 className="checkout-contact-detials-header">
                              Contact Number:{" "}
                              <span>{userDetails.user.contact_number}</span>
                            </h3>
                            <h3 className="checkout-contact-detials-header">
                              Address:{" "}
                              <span>
                                {
                                  userDetails?.user?.billing_address_details
                                    ?.billing_address
                                }
                              </span>
                            </h3>
                          </section>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="accordion-item shipping_method">
                  <div className="accordion-item_inner">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Shipping Method
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Form onChange={(e) => setShippingType(e.target.value)}>
                          {["radio"].map((type) => (
                            <div className="row">
                              <div className="col-md-6">
                                <Form.Check
                                  inline
                                  label="Collect from our manufacturer center"
                                  name="group2"
                                  value="Collect from our manufacturer center"
                                  type={type}
                                  id={`inline-${type}-11`}
                                />
                              </div>
                              <div className="col-md-6">
                                <Form.Check
                                  inline
                                  label="Shipping at your place"
                                  name="group2"
                                  value="Shipping at your place"
                                  type={type}
                                  id={`inline-${type}-22`}
                                />
                              </div>
                            </div>
                          ))}
                        </Form>
                        <br />
                        {shippingType === "Shipping at your place" && (
                          <Addresses
                            addresses={addresses}
                            handleChange={handleChangeShippingAddress}
                            handleAddAddress={handleAddAddress}
                          />
                        )}
                        <br />
                        {shippingType === "Shipping at your place" && (
                          <Button onClick={handleShowAddressForm}>
                            Add new address
                          </Button>
                        )}
                        <AddressFormModal
                          show={showAddressFormModal}
                          handleClose={handleCloseAddressForm}
                          handleAddAddress={handleAddAddress}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item payment_method">
                  <div className="accordion-item_inner">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Payment Method
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Form>
                          {["radio"].map((type) => (
                            <div className="row" key={`inline-${type}`}>
                              <div className="col-lg-3">
                                <Form.Check
                                  inline
                                  label="Online Payment"
                                  name="payment-method"
                                  value="card"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  onChange={handlePaymentMethod}
                                />
                              </div>
                              {userDetails?.user?.user_id === 3 && (
                                <div className="col-lg-3">
                                  <Form.Check
                                    inline
                                    label="Cash On Delivery"
                                    name="payment-method"
                                    value="cash"
                                    type={type}
                                    id={`inline-${type}-2`}
                                    onChange={handlePaymentMethod}
                                  />
                                </div>
                              )}
                              {/* <div className="col-lg-3">
                                <Form.Check
                                  inline
                                  label="Current Dated Check"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-3`}
                                />
                              </div> */}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div className="button_group_normal">
                  <ApiLoader />
                </div>
              ) : (
                <div className="button_group_normal">
                  <Link to="/ShoppingCart" className="button_space">
                    <Button
                      variant="primary"
                      type="submit"
                      className="gray_active"
                    >
                      Back
                    </Button>
                  </Link>
                  {/* <Link to="/ThankYou"> */}
                  <Button
                    variant="primary"
                    type="button"
                    className="loginButton_active"
                    onClick={handleOrder}
                  >
                    Proceed
                  </Button>
                  {/* </Link> */}
                </div>
              )}
            </div>

            <div className="right">
              <div className="cart_summary">
                <h3>Summary</h3>
                <ul>
                  <li>Subtotal</li>
                  <li>AED {totalPrice && totalPrice}</li>
                </ul>
                {shippingType === "Shipping at your place" && (
                  <ul>
                    <li>Shipping charge</li>
                    <li>AED {shippingCharge && shippingCharge}</li>
                  </ul>
                )}
                {/* <ul>
                  <li>Vat</li>
                  <li>AED 60</li>
                </ul> */}
                <ul className="total">
                  <li>Total</li>
                  <li>
                    AED{" "}
                    {shippingType === "Shipping at your place"
                      ? Number(
                          Number(totalPrice) + Number(shippingCharge)
                        ).toFixed(2)
                      : Number(totalPrice).toFixed(2)}
                  </li>
                </ul>

                {shippingType === "Shipping at your place" && (
                  <div className="doted_cart_box">
                    <p className="shipping_charge desktop_view">
                      <span>AED {shippingCharge && shippingCharge}</span>
                      <br />
                      added as shipping charges
                      <br />
                      at your place
                    </p>
                    {/* <p className="shipping_charge tab_view">
                      <span>AED {data.vat}</span> added as shipping charges at
                      your place
                    </p> */}
                    <p className="cart_shipping_icon">
                      <img src={cart_shipping_icon} loading="lazy" alt="" />
                    </p>
                    <p className="cart_total_amout">Total payable amount</p>
                    <p className="cart_payble_amount">
                      AED{" "}
                      {shippingCharge &&
                        Number(
                          Number(totalPrice) + Number(shippingCharge)
                        ).toFixed(2)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutCart>
  );
};

export default Checkout;
