import React from "react";
import Layout from "../Layout/Layout";
import FooterTop from "./FooterTop";
import MainBanner from "./MainBanner";
import NewsLetter from "./NewsLetter";
import WhatWeCut from "./WhatWeCut";
import Who_it_works from "./Who_it_works";

const Home = () => {
  return (
    <div>
      <div className="video_box">
        <video
          playsInline
          loop
          muted
          controls
          autoPlay
          alt="All the devices"
          src="https://thecuttingcenter.com/pub/media/VIDEO-2019-06-20-19-13-47.mp4"
        />
      </div>
      <div className="layout_outer">
        <Layout>
          <MainBanner />
          <Who_it_works />
          <WhatWeCut />
          <NewsLetter />
          <FooterTop />
        </Layout>
      </div>
    </div>
  );
};

export default Home;
