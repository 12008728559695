import React, {Component, Fragment } from 'react';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

export class Layout extends Component {
   

    render() {
        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="container-fluid1">
                        <div className="card_body">
                            <Header />
                                {this.props.children}
                            <Footer />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Layout
