import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
const AddressFormModal = ({ show, handleClose, handleAddAddress }) => {
  const [address, setAddress] = useState("");
  const handleChange = (event) => {
    setAddress(event.target.value);
  };
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add new address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="form-floating mb-3">
            <TextField
              id="address"
              label="Address"
              type="text"
              onChange={handleChange}
              fullWidth
              variant="outlined"
              helperText="Please ensure that the shipping address provided is located within the UAE region."
              required
            />
          </div>
        </div>
        <Button onClick={() => handleAddAddress(address)}>Save</Button>
      </Modal.Body>
    </Modal>
  );
};

export default AddressFormModal;
