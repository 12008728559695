import React, { Fragment, useEffect, useContext } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import { AuthContext } from "./context/authContextProvider";
import { UserContext } from "./context/userContextProvider";

import { Protected } from "./Routes/privateRoute";

import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import Contact from "./components/Contact/Contact";
import Signup from "./components/Signup/Signup";
import MyOrder from "./components/myOrder/MyOrder";
import Faq from "./components/FAQ/faq";
import OrderDetails from "./components/OrderDetails/OrderDetails";
import RigidSubstrates from "./components/RigidSubstrates/RigidSubstrates";
import GetAquote from "./components/GetAquote/GetAquote";
import ShoppingCart from "./components/ShoppingCart/ShoppingCart";
import Checkout from "./components/Checkout/Checkout";
import ThankYou from "./components/ThankYou/ThankYou";
import MyProfile from "./components/MyProfile/MyProfile";
import SignIn from "./components/SignIn/SignIn";
import Payment from "./components/Payment-Gateway/Payment";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./components/assets/css/style.css";

function App() {
  const { toggleAuth } = useContext(AuthContext);
  const { toggleUser } = useContext(UserContext);
  const checkUserAuthentication = async (token) => {
    return fetch(`${process.env.REACT_APP_NODE_BACKEND_URL}/user/check`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        toggleUser({
          user: res,
          token: token,
          msg: "Logged in!",
        });
        toggleAuth(token);
        return "true";
      })
      .catch((err) => {
        localStorage.removeItem("user-token");
        localStorage.removeItem("isLogin");
        return "false";
      });
  };
  useEffect(() => {
    if (localStorage.getItem("user-token")) {
      checkUserAuthentication(localStorage.getItem("user-token"));
    } else {
      localStorage.removeItem("user-token");
      localStorage.removeItem("isLogin");
    }
  }, []);

  // const Protected = ({ isLoggedIn, children }) => {
  //   if (!isLoggedIn) {
  //     return <Navigate to="/" replace />;
  //   }
  //   return children;
  // };
  return (
    <Fragment>
      {/* <div> */}
      <BrowserRouter basename="/">
        <Routes>
          <Route
            exact
            path="/payment"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/payment"
              >
                <Payment />
              </Protected>
            }
          />
          <Route
            exact
            path="/home"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/home"
              >
                <Home />
              </Protected>
            }
          />
          <Route
            exact
            path="/Dashboard"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/Dashboard"
              >
                <Dashboard />
              </Protected>
            }
          />
          <Route
            exact
            path="/Contact"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/Contact"
              >
                <Contact />
              </Protected>
            }
          />
          <Route
            exact
            path="/myOrder"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/myOrder"
              >
                <MyOrder />
              </Protected>
            }
          />
          <Route
            exact
            path="/faq"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/faq"
              >
                <Faq />
              </Protected>
            }
          />
          <Route
            exact
            path="/OrderDetails/:id"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/OrderDetails"
              >
                <OrderDetails />
              </Protected>
            }
          />
          <Route
            exact
            path="/RigidSubstrates"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/RigidSubstrates"
              >
                <RigidSubstrates />
              </Protected>
            }
          />
          <Route
            exact
            path="/GetAquote"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/GetAquote"
              >
                <GetAquote />
              </Protected>
            }
          />
          <Route
            exact
            path="/ShoppingCart"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/ShoppingCart"
              >
                <ShoppingCart />
              </Protected>
            }
          />
          <Route
            exact
            path="/Checkout"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/Checkout"
              >
                <Checkout />
              </Protected>
            }
          />
          <Route
            exact
            path="/ThankYou"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/ThankYou"
              >
                <ThankYou />
              </Protected>
            }
          />
          <Route
            exact
            path="/MyProfile"
            element={
              <Protected
                isLoggedIn={localStorage.getItem("user-token")}
                path="/MyProfile"
              >
                <MyProfile />
              </Protected>
            }
          />
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/" element={<SignIn />} />
          <Route exact path="/signup" element={<Signup />} />
          {/* <PrivateRoute authed={isAuth} path="/home" element={<Home} /> */}
        </Routes>
      </BrowserRouter>
      {/* </div> */}
    </Fragment>
  );
}

export default App;
