import React, { useState, useContext, useEffect } from "react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { TextField } from "@mui/material";

import { UserContext } from "../../context/userContextProvider";
import { AuthContext } from "../../context/authContextProvider";
import axiosInstance from "../../helpers/axios";

import login_logo from "../assets/images/login_logo.png";

const ForgotPassword = () => {
  const { id } = useParams();
  const { isAuth, toggleAuth } = useContext(AuthContext);
  const { toggleUser } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // const showPassword = () => {
  //   setShow((prev) => !prev);
  // };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleResetPassword = async () => {
    try {
      setDisabled(true);
      const payload = {
        email: email,
      };
      const { data } = await axiosInstance.post(
        "/user/forgot-password",
        payload
      );
      setDisabled(false);
      Swal.fire({
        title: "Success",
        text: "Please check your inbox, reset password mail sent to your registered email address.",
        icon: "success",
        confirmButtonText: "Okay",
      }).then(() => navigate("/signin"));
    } catch (error) {
      if (error.status === 404) {
        swal({
          title: "USER NOT FOUND",
          text: "Please sign up first.",
          icon: "warning",
          buttons: {
            roll: {
              className: "signin-error-modal-btn",
              text: "Sign Up",
              value: "signup",
            },
          },
        }).then((res) => res === "signup" && navigate("/signup"));
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setDisabled(true);
      if (password === confirmPassword) {
        const payload = {
          password: password,
          token: resetToken,
        };
        const { data } = await axiosInstance.post(
          "/user/change-password",
          payload
        );
        setDisabled(false);
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Password changed successfully",
          timer: 3000,
        }).then(() => navigate("/signin"));
      } else {
        setDisabled(false);
        Swal.fire({
          title: "Password not matched",
          icon: "error",
          text: "Your confirm password is not match with your new password",
          timer: 3000,
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Unable to change the password, please try again later.",
        timer: 3000,
      });
    }
  };
  if (isAuth) {
    navigate("/home");
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get("token");
    setResetToken(resetToken);
  }, []);
  return (
    <>
      <div className="login_outer">
        <section>
          <div className="container">
            <div className="row loginBox">
              <div className="col-md-6 loginBox_left">
                <img src={login_logo} className="" loading="lazy" alt="" />
                <p className="middle_loginBox">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <p className="footer_loginBox">
                  All Rights Reserved © 2023 Blue Rhine
                </p>
              </div>
              <div className="col-md-6">
                <div className="wrapper_login">
                  <div className="heading">
                    <h1>Reset your password</h1>
                  </div>

                  {!resetToken ? (
                    <Form>
                      <div className="form-floating mb-3">
                        <TextField
                          label="Registered email"
                          placeholder="name@example.com"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          fullWidth
                          variant="outlined"
                          required
                        />
                        {/* <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label for="floatingInput">Email address</label> */}
                      </div>
                      {/* <div className="form-floating mb-3">
                      <TextField
                        label="Password"
                        placeholder="...."
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={handleTogglePassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                        required
                      />
                      
                    </div> */}
                      {/* <div className="form-floating mb-3">
                      <TextField
                        label="Confirm password"
                        placeholder="...."
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={handleTogglePassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                        required
                      />
                    </div> */}
                      <div className="button_group">
                        <Button
                          variant="primary"
                          className="loginButton_active"
                          disabled={disabled}
                          onClick={() => handleResetPassword()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Form>
                  ) : (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-floating mb-3">
                        <TextField
                          label="New password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </div>
                      <div className="form-floating mb-3">
                        <TextField
                          label="Confirm password"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </div>
                      <div className="button_group">
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={disabled}
                          className="loginButton_active"
                        >
                          Reset
                        </Button>
                      </div>
                    </Form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ForgotPassword;
