import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import axiosInstance from "../../helpers/axios";
import { UserContext } from "../../context/userContextProvider";

import LayoutInner from "../LayoutInner/LayoutInner";

import breadcrumb_home_icon from "../assets/images/breadcrumb_home.png";
import view_icon from "../assets/images/view_icon.png";
// import up_arow_icon from "../assets/images/up_arow.png";

const MyOrder = () => {
  const { userDetails } = useContext(UserContext);
  const navigate = useNavigate();
  const [orders, setOrders] = useState("");
  const handleShow = async (item) => {
    navigate(`/OrderDetails/${item.order_item_id}`);
  };
  const getOrders = async () => {
    const res =
      userDetails &&
      (await axiosInstance.get(
        `/order/items?userId=${userDetails?.user.user_id}`
      ));
    setOrders(res.data);
  };
  useEffect(() => {
    getOrders();
  }, [userDetails]);
  return (
    <LayoutInner>
      <div className="innerpage myorder_page">
        <div className="container">
          <div className="breadcrumb_container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <img src={breadcrumb_home_icon} loading="lazy" alt="" />
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">My Order</li>
              </ol>
            </nav>
          </div>
          <div className="teable_head">
            <div className="teable_head_left">
              <h1>My Orders</h1>
            </div>
            {/* <div className="teable_head_right">
              <ul>
                <li>5 order placed in </li>
                <li>
                  <select
                    className="form-select custom_select"
                    aria-label="Default select example"
                  >
                    <option selected>Past 3 months</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </li>
              </ul>
            </div> */}
          </div>

          {/* <Modal
            //   dialogClassName={style.modal90w}

            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Item Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: "center" }}>
                {selectedItem && (
                  <>
                    <p>
                      <strong style={{ fontWeight: 700 }}>Item ID:</strong>{" "}
                      <span>{selectedItem.cart_item_id}</span>
                    </p>
                    <p>
                      <strong style={{ fontWeight: 700 }}>Material:</strong>{" "}
                      <span>{selectedItem.material}</span>
                    </p>
                    <p>
                      <strong style={{ fontWeight: 700 }}>Thickness:</strong>{" "}
                      <span>{selectedItem.thickness}</span>
                    </p>
                    <p>
                      <strong style={{ fontWeight: 700 }}>Color:</strong>{" "}
                      <span>{selectedItem.color}</span>
                    </p>
                    <p>
                      <strong style={{ fontWeight: 700 }}>Finish:</strong>{" "}
                      <span>{selectedItem.finish}</span>
                    </p>
                    <p>
                      <strong style={{ fontWeight: 700 }}>Quantity:</strong>{" "}
                      <span>{selectedItem.quantity}</span>
                    </p>
                    <p>
                      <strong style={{ fontWeight: 700 }}>Price:</strong>{" "}
                      <span>{selectedItem.price}</span>
                    </p>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}

          <div className="teable_body">
            <div className="table-responsive">
              <table className="table my_order_table">
                <thead>
                  <tr>
                    {/* <th scope="col">
                      Order Date{" "}
                      <img src={up_arow_icon} loading="lazy" alt="" />
                    </th> */}
                    <th scope="col" style={{ textAlign: "center" }}>
                      Order ID
                      {/* <img src={up_arow_icon} loading="lazy" alt="" /> */}
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Material Type{" "}
                      {/* <img src={up_arow_icon} loading="lazy" alt="" /> */}
                    </th>
                    {/* <th scope="col" style={{ textAlign: "center" }}>
                      Status
                    </th> */}
                    <th scope="col" style={{ textAlign: "center" }}>
                      Price
                      {/* <img src={up_arow_icon} loading="lazy" alt="" /> */}
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Quantity
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {orders &&
                    orders.map((item) => (
                      <tr
                        key={item.cart_item_id}
                        style={{ textAlign: "center" }}
                      >
                        {/* <td scope="row">{item.order_date}</td> */}
                        <td>{item.order_item_id}</td>
                        <td>{item.material_name}</td>
                        {/* <td>
                          <span className="pending_table_button">
                            {item.status}
                          </span>
                        </td> */}
                        <td>AED {item.selling_price}</td>
                        <td>{item.quantity}</td>
                        {/* <td>
                          <a href="#" className="review_table_button">
                            Write a Review
                          </a>
                        </td> */}
                        <td>
                          <img
                            src={view_icon}
                            loading="lazy"
                            alt=""
                            onClick={() => handleShow(item)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </LayoutInner>
  );
};

export default MyOrder;
