import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";

import instance from "../../helpers/axios";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubscribe = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await instance.post("/subscribe/newsletter", {
        email: email,
      });
      Swal.fire({
        title: "Successfully subscribed",
        text: "Thank you for subscribing our newsletter!",
        timer: 3000,
        backdrop: false,
      });

      setIsSubmitting(false);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error,
        timer: 3000,
        icon: "error",
      });
      setIsSubmitting(false);
    }
  };
  return (
    <div className="newletter_outer">
      <div className="container newletter_inner">
        <div className="row newletter_inner_row">
          <div className="col-xl-5">
            <h6>newsletter subscription</h6>
            <h2>We really want you to want us </h2>
          </div>
          <div className="col-xl-7">
            <Form onSubmit={handleSubscribe}>
              <div className="newletter_form">
                <Form.Group className="newsletter_email" controlId="">
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="newsletter_button"
                  disabled={isSubmitting}
                >
                  Yes, Subscribe me
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
