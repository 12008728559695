import React from "react";

import shipping_icon from "../assets/images/shipping_icon.png";
import reach_us_icon from "../assets/images/reach_us_icon.png";
import support_icon from "../assets/images/support_icon.png";
import address_icon from "../assets/images/address_icon.png";
import phone_icon from "../assets/images/phone_icon.png";
import mail_icon from "../assets/images/mail_icon.png";

const FooterTop = () => {
  return (
    <div className="footer_top_outer">
      <div className="footer_top_home container">
        <div className="row">
          <div className="col-lg-4">
            <ul className="footer_top_home_ul">
              <li>
                <img
                  src={shipping_icon}
                  className="home_logo"
                  loading="lazy"
                  alt=""
                />
              </li>
              <li className="heading">Shipping</li>
              <li className="text">
                Cut sheets are packed and delivered anywhere in the UAE
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul className="footer_top_home_ul">
              <li>
                <img
                  src={reach_us_icon}
                  className="home_logo"
                  loading="lazy"
                  alt=""
                />
              </li>
              <li className="heading">Online Support</li>
              <li className="text">
                See your price and make payment instantly online
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul className="footer_top_home_ul">
              <li>
                <img
                  src={support_icon}
                  className="home_logo"
                  loading="lazy"
                  alt=""
                />
              </li>
              <li className="heading">Reach Us</li>
              <li className="text">
                <ul className="Reach_Us_ul">
                  <li>
                    <img
                      src={address_icon}
                      className="home_logo"
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li className="text">
                    Blue Rhine Industries L.L.C P.O Box No: 114001,Plot 597-673,
                    Dubai Investment Park- 2 Dubai United Arab Emirates.
                  </li>
                </ul>
                <ul className="Reach_Us_ul">
                  <li className="text">
                    <img
                      src={phone_icon}
                      className="home_logo"
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>+97148231444 </li>
                </ul>
                <ul className="Reach_Us_ul">
                  <li>
                    <img
                      src={mail_icon}
                      className="home_logo"
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li className="text">support@thecuttingcenter.com</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTop;
