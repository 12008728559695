import React from 'react'

const MainBanner = () => {
  return (
    <div className='MainBannerBox container' >
      <h1>Big range of metals</h1>
      <h1>and acrylic cut to your design</h1>
    </div>
  )
}

export default MainBanner