import React from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import API from "../../helpers/axios";

import LayoutInner from "../LayoutInner/LayoutInner";

import styles from "./Contact.module.css";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (data) => {
    try {
      await API.post("/contactUs", data);
      Swal.fire({
        title: "Message sent",
        text: "Thank you for contacting us",
        icon: "success",
        timer: 3000,
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong, Please try again.",
        icon: "error",
        timer: 3000,
      });
    }
  };
  return (
    <LayoutInner>
      <Form className={styles.formContainer}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="fullName"
            placeholder="Enter Full Name"
            {...register("fullName", {
              required: true,
            })}
          />
          {errors.fullName && (
            <p style={{ color: "red", width: "260%" }}>
              Please wrote your full name
            </p>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          {errors.email && (
            <p style={{ color: "red", width: "260%" }}>
              Please check the Email
            </p>
          )}
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control
            type="mobileNumber"
            placeholder="Enter your mobile number"
            {...register("mobileNumber", {
              required: true,
              minLength: 10,
              maxLength: 10,
            })}
          />
          {errors.mobileNumber && (
            <p style={{ color: "red", width: "260%" }}>
              Please check your mobile number
            </p>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            type="companyName"
            placeholder="Enter your company name"
            {...register("companyName", {
              required: true,
            })}
          />
          {errors.companyName && (
            <p style={{ color: "red", width: "260%" }}>
              Please wrote your company name
            </p>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Write your message</Form.Label>
          <Form.Control
            as="textarea"
            type="message"
            placeholder="Write down your message here...."
            {...register("message", {
              required: true,
            })}
          />
          {errors.message && (
            <p style={{ color: "red", width: "260%" }}>
              Please write some message.
            </p>
          )}
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit(handleFormSubmit)}
        >
          Submit
        </Button>
      </Form>
    </LayoutInner>
  );
};
export default Contact;
