import React, { useEffect, useState } from "react";
import LayoutInner from "../LayoutInner/LayoutInner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import breadcrumb_home_icon from "../assets/images/breadcrumb_home.png";
import upload_icon from "../assets/images/upload_icon.png";
import rigid_01 from "../assets/images/rigid_01.png";
import rigid_02 from "../assets/images/rigid_02.png";
import rigid_03 from "../assets/images/rigid_03.png";
import rigid_04 from "../assets/images/rigid_04.png";
import rigid_th_01 from "../assets/images/rigid_th_01.png";
import rigid_th_02 from "../assets/images/rigid_th_02.png";
import rigid_th_03 from "../assets/images/rigid_th_03.png";
import rigid_th_04 from "../assets/images/rigid_th_04.png";
import left_link_icon from "../assets/images/left_link.png";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
// import "swiper/css/pagination";

import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/thumbs/thumbs.min.css";

// import required modules
import { Navigation } from "swiper";
import { FreeMode, Thumbs } from "swiper";

const GetAquote = () => {
  const location = useLocation();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [details, setDetails] = useState(location.state.productDetails);

  const navigate = useNavigate();
  useEffect(() => {
    setDetails({ ...details, total_price: 430 });
  }, []);
  return (
    <LayoutInner>
      <div className="innerpage Rigid_page">
        <div className="container">
          <div className="breadcrumb_container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/Home">
                    <img src={breadcrumb_home_icon} loading="lazy" alt="" />
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">Rigid Substrates</li>
              </ol>
            </nav>
          </div>

          <div className="teable_head">
            <div className="teable_head_left">
              <Link to="" className="back_cart">
                <img src={left_link_icon} loading="lazy" alt="" />
              </Link>
              <h1>Rigid Substrates</h1>
            </div>
            <div className="teable_head_right"></div>
          </div>
          <form>
            <div className="teable_body rigdDetails_body">
              <div className="left">
                <div className="swiper_cover">
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    loop={true}
                    spaceBetween={2}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                  >
                    <SwiperSlide>
                      <img src={rigid_01} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_02} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_03} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_04} loading="lazy" alt="" />
                    </SwiperSlide>
                  </Swiper>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    spaceBetween={2}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <img src={rigid_th_01} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_th_02} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_th_03} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_th_04} loading="lazy" alt="" />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <div className="right">
                <div className="rigdDetails_right_content custom_select_form">
                  <p className="upload_file_top_text">
                    It is simple. Choose options and upload your design. Click
                    on 'Get A Quote' to see your price instantly. We do not
                    require your entire design file but the cut-file. Your
                    cut-file in dxf format makes our price calculation accurate.
                  </p>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="">
                        <p
                          for="formFile"
                          className="form-label upload_file_label_text"
                        >
                          Upload Drawing
                        </p>
                        <div className="file_upload_cover">
                          <p className="upload_file_name"></p>
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                          <img src={upload_icon} loading="lazy" alt="" />
                        </div>
                        <p className="form_small_text">
                          *Please upload cut-file drawing in dxf format
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group
                        className="mt-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Material will be provided by me"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row from_row_gap">
                    <div className="col-lg-6">
                      <p
                        for="formFile"
                        className="form-label upload_file_label_text"
                      >
                        Linear-meters of your drawing in Meters.
                      </p>
                      <select
                        className="form-select custom_select"
                        aria-label="Default select example"
                      >
                        <option selected>0.9</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <p
                        for="formFile"
                        className="form-label upload_file_label_text"
                      >
                        Choose Material
                      </p>
                      <select
                        className="form-select custom_select"
                        aria-label="Default select example"
                      >
                        <option selected>Acrylic</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>

                  <div className="row from_row_gap">
                    <div className="col-lg-6">
                      <p
                        for="formFile"
                        className="form-label upload_file_label_text"
                      >
                        Choose Thickness
                      </p>
                      <select
                        className="form-select custom_select"
                        aria-label="Default select example"
                      >
                        <option value="1">2 +AED 23.10</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <p
                        for="formFile"
                        className="form-label upload_file_label_text"
                      >
                        Choose Color
                      </p>
                      <select
                        className="form-select custom_select"
                        aria-label="Default select example"
                      >
                        <option value="1">Gold +AED 103.40</option>
                        <option value="2">black</option>
                        <option value="3">green</option>
                      </select>
                    </div>
                  </div>

                  <div className="row from_row_gap">
                    <div className="col-lg-6">
                      <p
                        for="formFile"
                        className="form-label upload_file_label_text"
                      >
                        Choose Finish
                      </p>
                      <select
                        className="form-select custom_select"
                        aria-label="Default select example"
                      >
                        <option value="1">Mirror</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <p
                        for="formFile"
                        className="form-label upload_file_label_text"
                      >
                        Quantity
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="button_group_normal">
                    <span className="button_space">
                      <Button
                        variant="primary"
                        type="submit"
                        className="loginButton_active"
                        disabled
                      >
                        Get A Quote
                      </Button>
                    </span>

                    <Button
                      variant="primary"
                      type="submit"
                      className="gray_active"
                      disabled
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="add_to_cart_bottom_strip">
            <div>
              <span className="price">Price:</span>{" "}
              <span className="price_value">AED 299.25</span>{" "}
              <span className="vat">(Price inclusive of VAT)</span>
            </div>

            <Button
              className="add_to_cart"
              onClick={() =>
                navigate("/ShoppingCart", {
                  state: {
                    productDetails: details,
                  },
                })
              }
            >
              Add to Cart
            </Button>
          </div>
        </div>
      </div>
    </LayoutInner>
  );
};

export default GetAquote;
