import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { TextField, ListItem, ListItemText } from "@mui/material";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";

import axiosInstance from "../../helpers/axios";
import { UserContext } from "../../context/userContextProvider";

import LayoutInner from "../LayoutInner/LayoutInner";
import AddressList from "./AddressList";
import AddressEditModal from "./AddressEditModal";
import AddressFormModal from "../Checkout/AddressFormModal";

import phone_icon from "../assets/images/phone_icon.png";
import mail_icon from "../assets/images/mail_icon.png";
import breadcrumb_home_icon from "../assets/images/breadcrumb_home.png";
import profile_big_image from "../assets/images/profile_big_image.png";
import password_icon_white from "../assets/images/password_icon_white.png";
import user_icon_white from "../assets/images/user_icon_white.png";

import style from "./MyProfile.module.css";

var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const MyProfile = () => {
  let { userDetails } = useContext(UserContext);
  const navigate = useNavigate();
  var date = userDetails.user && new Date(userDetails.user.created_at);
  var month = userDetails.user && months[date.getMonth()];
  var day = userDetails.user && date.getDate();
  var year = userDetails.user && date.getFullYear();
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [show, setShow] = useState(false);
  const [profilePicUpdate, setProfilePicUpdate] = useState(false);
  const [closeProfilePicUpdate, setCloseProfilePicUpdate] = useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState({});
  const [userPayload, setUserPayload] = useState({});
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [password, setPassword] = useState(null);
  const [imageData, setImageData] = useState("");
  const [showAddressEditModal, setShowAddressEditModal] = useState(false);
  const [showAddressFormModal, setShowAddressFormModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closePasswordUpdate = () => setPasswordUpdate(false);
  const showPasswordChangeInputBox = () => setPasswordUpdate(true);
  const handleShowAddressForm = () => setShowAddressFormModal(true);
  const handleCloseAddressForm = () => setShowAddressFormModal(false);
  const handleChange = async (event) => {
    event.preventDefault();
    try {
      await axiosInstance.post(`/user/update`, userPayload);
      Swal.fire({
        title: "Success",
        text: `${userPayload?.first_name} ${userPayload?.last_name}'s details updated successfully.`,
        icon: "success",
        confirmButtonText: "Ok",
        timer: 2500,
      });
      getUserProfile();
      handleClose();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        timer: 3000,
      });
    }
  };
  const getUserProfile = async () => {
    try {
      const res = await axiosInstance.get(`/user/profile`);
      setUserProfileDetails(res?.data[0]);
      setEmail(res?.data[0].email);
      setAddress(res?.data[0].address);
      setPhoneNumber(res?.data[0].contact_number);
      setUserPayload(res?.data[0]);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "something went wrong!",
        confirmButtonText: "Okay",
        allowOutsideClick: false,
      }).then(() => navigate("/RigidSubstrates"));
    }
  };
  const handleAddressChange = (event) => {
    const { id, value } = event.target;
    setAddress({ ...address, [id]: value });
  };
  const handleAddressDelete = async (address_id) => {
    try {
      await axiosInstance.delete("/address/delete/" + address_id);
      getUserProfile();
      Swal.fire({
        title: "Success",
        text: "Address deleted successfully",
        icon: "success",
        timer: 3000,
      });
    } catch (error) {
      Swal.fire({
        title: "Something went wrong",
        text: JSON.stringify(error),
        timer: 3000,
      });
    }
  };
  const handleAddressEdit = (address) => {
    setShowAddressEditModal(true);
    setAddress(address);
  };
  const handleAddressUpdate = async () => {
    try {
      await axiosInstance.put("/address/update", address);
      setShowAddressEditModal(false);
      setAddress({});
      getUserProfile();
      Swal.fire({
        title: "Success",
        text: "Address updated",
        icon: "success",
        timer: 3000,
      });
    } catch (error) {
      Swal.fire({
        title: "Something went wrong",
        text: JSON.stringify(error),
        timer: 3000,
      });
    }
  };
  const handleCloseAddressEditModal = () => setShowAddressEditModal(false);
  const handleChangeProfilePic = () => {
    setProfilePicUpdate((prev) => !prev);
  };
  const changePassword = async (password) => {
    try {
      const data = {
        password: password,
      };
      await axiosInstance.post("/user/update/password", data);
      Swal.fire({
        title: "Success",
        icon: "success",
        text: `Your Password has been updated successfully`,
        showConfirmButton: true,
        confirmButtonText: "Okay",
        timer: 3000,
      });
      closePasswordUpdate();
      localStorage.removeItem("user-token");
      navigate("/");
    } catch (error) {
      Swal.fire({
        title: "Error!",
        icon: "warning",
        html: `<h4>${error}</h4><br/>`,
        confirmButtonText: "Okay",
        timer: 3000,
      });
    }
  };
  const changeProfilePic = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        },
      };
      const formData = new FormData();
      formData.append("image", imageData);
      await axiosInstance.post("/user/updateProfilePicture", formData, config);
      handleChangeProfilePic();
      getUserProfile();
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddAddress = async (address) => {
    try {
      await axiosInstance.post("/address/add", { address: address });
      getUserProfile();
      handleCloseAddressForm();
      Swal.fire({
        title: "Success",
        text: "Addresses added successfully",
        icon: "success",
        confirmButtonText: "Okay",
      });
    } catch (error) {
      Swal.fire({
        title: "Something went wrong",
        text: error,
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  const handleUpdateBillingAddress = async (address_id) => {
    try {
      const res = await axiosInstance.put("/user/update/billing-address", {
        address_id: address_id,
      });
      getUserProfile();
      handleCloseAddressForm();
      Swal.fire({
        title: "Success",
        text: "Billing address updated successfully",
        icon: "success",
        confirmButtonText: "Okay",
      });
    } catch (error) {
      Swal.fire({
        title: "Something went wrong",
        text: error,
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  useEffect(() => {
    userDetails && userDetails.user.email && setEmail(userDetails.user.email);
    userDetails &&
      userDetails.user.contact_number &&
      setPhoneNumber(userDetails.user.contact_number);
    userDetails &&
      userDetails.user.address &&
      setAddress(userDetails.user.address);
  }, [userProfileDetails]);
  useEffect(() => {
    getUserProfile();
  }, []);
  return (
    <LayoutInner>
      <>
        <div className="innerpage MyProfile_page">
          <div className="container">
            <div className="breadcrumb_container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/home">
                      <img src={breadcrumb_home_icon} loading="lazy" alt="" />
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">My Profile</li>
                </ol>
              </nav>
            </div>

            <div className="teable_head">
              <div className="teable_head_left">
                <h1>User Details</h1>
              </div>
              <div className="teable_head_right"></div>
            </div>
            <div className="MyProfile_body">
              <div className="row">
                <div className="col-md-6 col-lg-8 left">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-6">
                      <img
                        className={`${style.profile_image_pic} "profile_image"`}
                        src={
                          userProfileDetails &&
                          userProfileDetails?.profile_picture
                            ? userProfileDetails?.profile_picture
                            : profile_big_image
                        }
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                      <h3>
                        {userDetails.user && userDetails.user.first_name}{" "}
                        {userDetails.user && userDetails.user.last_name}
                      </h3>
                      {/* <ul>
                                        <li>+971 4 823 1444</li>
                                        <li>support@thecuttingcenter.com</li>
                                        <li>Dubai Investments Park 2, Dubai,United Arab Emirates</li>
                                    </ul>
                                     */}
                      <ul className="Reach_Us_ul">
                        <li className="text">
                          <img
                            src={phone_icon}
                            className="home_logo"
                            loading="lazy"
                            alt=""
                          />
                        </li>
                        {phoneNumber ? (
                          <li>{phoneNumber}</li>
                        ) : (
                          <li> ------ </li>
                        )}
                      </ul>
                      <ul className="Reach_Us_ul">
                        <li>
                          <img
                            src={mail_icon}
                            className="home_logo"
                            loading="lazy"
                            alt=""
                          />
                        </li>
                        {email ? (
                          <li className="text">
                            {userDetails.user && userDetails.user.email}
                          </li>
                        ) : (
                          <li> ------ </li>
                        )}
                      </ul>
                      <Button
                        variant="primary"
                        className="loginButton_active"
                        onClick={handleShow}
                      >
                        Edit Profile Info
                      </Button>

                      <Modal
                        //   dialogClassName={style.modal90w}

                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={show}
                        onHide={handleClose}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Update Profile Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-12 col-sm-6 col-lg-6">
                              <img
                                // className="profile_image"
                                src={
                                  userProfileDetails?.profile_picture
                                    ? userProfileDetails?.profile_picture
                                    : profile_big_image
                                }
                                loading="lazy"
                                alt=""
                                style={{ height: "300px", marginLeft: "10%" }}
                              />
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6">
                              <form
                                id="userProfileUpdateForm"
                                onSubmit={handleChange}
                              >
                                <div className="form-floating mb-3">
                                  <TextField
                                    id="first_name"
                                    label="First name"
                                    type="text"
                                    defaultValue={
                                      userProfileDetails?.first_name
                                    }
                                    onChange={(e) =>
                                      setUserPayload({
                                        ...userPayload,
                                        [e.target.id]: e.target.value,
                                      })
                                    }
                                    fullWidth
                                    variant="outlined"
                                    required
                                  />
                                </div>
                                <div className="form-floating mb-3">
                                  <TextField
                                    id="last_name"
                                    label="Last name"
                                    type="text"
                                    defaultValue={userProfileDetails?.last_name}
                                    onChange={(e) =>
                                      setUserPayload({
                                        ...userPayload,
                                        [e.target.id]: e.target.value,
                                      })
                                    }
                                    fullWidth
                                    variant="outlined"
                                    required
                                  />
                                </div>
                                <div className="form-floating mb-3">
                                  <TextField
                                    id="email"
                                    label="Email"
                                    type="email"
                                    defaultValue={userProfileDetails?.email}
                                    fullWidth
                                    variant="outlined"
                                    disabled
                                  />
                                </div>
                                <div className="form-floating mb-3">
                                  <TextField
                                    label="Contact Number"
                                    type="number"
                                    defaultValue={
                                      userProfileDetails?.contact_number
                                    }
                                    id="contact_number"
                                    onChange={(e) =>
                                      setUserPayload({
                                        ...userPayload,
                                        [e.target.id]: e.target.value,
                                      })
                                    }
                                    fullWidth
                                    variant="outlined"
                                    required
                                  />
                                </div>

                                {/* <div className="form-floating mb-3">
                                  <TextField
                                    id="address"
                                    label="Address"
                                    type="text"
                                    defaultValue={userProfileDetails?.address}
                                    onChange={(e) =>
                                      setUserPayload({
                                        ...userPayload,
                                        [e.target.id]: e.target.value,
                                      })
                                    }
                                    fullWidth
                                    variant="outlined"
                                    helperText="Please ensure that the shipping address provided is located within the UAE region."
                                    required
                                  />
                                </div> */}

                                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Sign Up for Newsletter"
                      />
                    </Form.Group> */}
                              </form>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            form="userProfileUpdateForm"
                          >
                            Save Changes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 right">
                  <p className="heading">
                    You joined in {month} {day}, {year}
                  </p>
                  <p className="text"></p>
                  {/* <p className="text">Last login in 5 minutes ago</p> */}
                  <Modal
                    //   dialogClassName={style.modal90w}

                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={profilePicUpdate}
                    onHide={closeProfilePicUpdate}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Change Profile Pic</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-lg-6">
                          <h3 className={style.userName}>
                            {userDetails.user && userDetails.user.first_name}{" "}
                            {userDetails.user && userDetails.user.last_name}
                          </h3>
                          <ul className="Reach_Us_ul">
                            <li>
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                className={style.inputBox}
                                placeholder="Enter new password"
                                onChange={(e) =>
                                  setImageData(e.target.files[0])
                                }
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleChangeProfilePic}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => changeProfilePic(imageData)}
                      >
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <p className="right_button">
                    <Button className="" onClick={handleChangeProfilePic}>
                      <img
                        src={user_icon_white}
                        className="home_logo"
                        loading="lazy"
                        alt=""
                      />{" "}
                      Change Profile Image
                    </Button>
                  </p>
                  <Modal
                    //   dialogClassName={style.modal90w}

                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={passwordUpdate}
                    onHide={closePasswordUpdate}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-lg-6">
                          <h3 className={style.userName}>
                            {userDetails.user && userDetails.user.first_name}{" "}
                            {userDetails.user && userDetails.user.last_name}
                          </h3>
                          <ul className="Reach_Us_ul">
                            {/* <li className="text">
                              <img
                                src={password_icon_white}
                                className="home_logo"
                                loading="lazy"
                                alt=""
                                color="black"
                                style={{ backgroundColor: "black" }}
                              />
                            </li> */}

                            <li>
                              <label>Enter your new password</label>
                              <input
                                className={style.inputBox}
                                placeholder="xxxxxxxxxxx"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={closePasswordUpdate}>
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => changePassword(password)}
                      >
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <p className="right_button">
                    <Button onClick={showPasswordChangeInputBox}>
                      <img
                        src={password_icon_white}
                        className="home_logo"
                        loading="lazy"
                        alt=""
                      />{" "}
                      Change Password
                    </Button>
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div className="MyProfile_body">
              <div className="teable_head">
                <div className="teable_head_left addresses">
                  <h1>Billing address</h1>
                </div>
              </div>

              {userProfileDetails && (
                <div className="row">
                  <div className="col-12 col-sm-12 col-lg-12">
                    <ListItem className="mb-2 border ">
                      <ListItemText
                        primary={
                          userProfileDetails?.billing_address_details
                            ?.billing_address
                        }
                      />
                      {/* <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          //onClick={() => onEdit(address)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          //onClick={() => onDelete(address.address_id)}
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction> */}
                    </ListItem>
                  </div>
                </div>
              )}
              {showAddressEditModal && (
                <AddressEditModal
                  show={showAddressEditModal}
                  address={address}
                  handleClose={handleCloseAddressEditModal}
                  handleChange={handleAddressChange}
                  handleSubmit={handleAddressUpdate}
                />
              )}
            </div>
            <br />
            <div className="MyProfile_body">
              <div className="teable_head">
                <div className="teable_head_left addresses">
                  <h1>Addresses</h1>
                </div>
                <div>
                  <Button
                    className=""
                    style={{ marginRight: "2rem" }}
                    onClick={handleShowAddressForm}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <AddressFormModal
                show={showAddressFormModal}
                handleClose={handleCloseAddressForm}
                handleAddAddress={handleAddAddress}
              />
              {userProfileDetails && (
                <AddressList
                  addresses={userProfileDetails?.addresses}
                  onEdit={handleAddressEdit}
                  onDelete={handleAddressDelete}
                  billing_address_id={
                    userProfileDetails?.billing_address_details
                      ?.billing_address_id
                  }
                  handleUpdateBillingAddress={handleUpdateBillingAddress}
                />
              )}
              {showAddressEditModal && (
                <AddressEditModal
                  show={showAddressEditModal}
                  address={address}
                  handleClose={handleCloseAddressEditModal}
                  handleChange={handleAddressChange}
                  handleSubmit={handleAddressUpdate}
                />
              )}
            </div>
          </div>
        </div>
      </>
    </LayoutInner>
  );
};

export default MyProfile;
