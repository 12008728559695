import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";

import instance from "../../helpers/axios";

import LayoutInner from "../LayoutInner/LayoutInner";

import breadcrumb_home_icon from "../assets/images/breadcrumb_home.png";
import search_icon from "../assets/images/search_icon.png";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);

  const getAllFaqs = async () => {
    try {
      const { data } = await instance.get("/faq/all");
      setFaqs(data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        timer: 3000,
      });
    }
  };
  useEffect(() => {
    getAllFaqs();
  }, []);
  return (
    <LayoutInner>
      <div className="innerpage faq_page">
        <div className="container">
          <div className="breadcrumb_container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <img src={breadcrumb_home_icon} loading="lazy" alt="" />
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">Faq</li>
              </ol>
            </nav>
          </div>

          <div className="teable_head">
            <div className="teable_head_left">
              <h1>FAQ</h1>
            </div>
            <div className="teable_head_right">
              <div className="input-group search_FAQ_div">
                <input
                  type="text"
                  className="form-control search_FAQ"
                  placeholder="Type something to search.."
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                >
                  <img src={search_icon} loading="lazy" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="teable_body faq_body">
            <div className="accordion" id="accordionExample">
              {(faqs?.length === 0 || faqs === undefined) && (
                <div>We will add some faqs soon.</div>
              )}
              {faqs?.map((item) => (
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {item.faq_question}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{item.faq_answer}</div>
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="pagination_outer">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item previous_pagi disabled">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <img src={left_arrow_icon} loading="lazy" alt="" />
                      </span>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item next_pagi">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <img src={right_arrow_icon} loading="lazy" alt="" />
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}
          </div>
        </div>
      </div>
    </LayoutInner>
  );
};

export default Faq;
