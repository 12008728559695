import React from "react";

import image_slide_01 from "../assets/images/image_slide_01.png";
import image_slide_02 from "../assets/images/image_slide_02.png";
import image_slide_03 from "../assets/images/image_slide_03.png";
import image_slide_04 from "../assets/images/image_slide_04.png";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
// import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

const WhatWeCut = () => {
  return (
    <div className="what_we_cut_outer">
      <div className="container what_we_cut_outer_inner">
        <h6>What We Cut</h6>
        <h2>Nullam faucibus a risus vel</h2>
        <Swiper
          slidesPerView={4}
          slidesPerGroup={4}
          // cssMode={true}
          //navigation={true}
          // Autoplay={true}
          spaceBetween={10}
          // mousewheel={true}
          // keyboard={true}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
          breakpoints={{
            // when window width is >= 375
            375: {
              //width: 640,
              slidesPerView: 1,
            },
            // when window width is >= 375
            414: {
              //width: 414,
              slidesPerView: 2,
            },
            // when window width is >= 640px
            640: {
              //width: 640,
              slidesPerView: 3,
            },
            // when window width is >= 768px
            768: {
              //width: 768,
              slidesPerView: 3,
            },
            1024: {
              //width: 768,
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide>
            <img
              src={image_slide_01}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_02}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_03}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_04}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_01}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_02}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_03}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_04}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_01}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_02}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_03}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_04}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={image_slide_01}
              className="home_logo"
              loading="lazy"
              alt=""
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default WhatWeCut;
