import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import { saveAs } from "file-saver";

import axiosInstance from "../../helpers/axios";
import { UserContext } from "../../context/userContextProvider";

import LayoutInner from "../LayoutInner/LayoutInner";

import breadcrumb_home_icon from "../assets/images/breadcrumb_home.png";
import address_icon from "../assets/images/address_icon.png";
import name_icon from "../assets/images/name.png";
import phone_icon from "../assets/images/phone_icon.png";
import mail_icon_icon from "../assets/images/mail_icon.png";
import left_link_icon from "../assets/images/left_link.png";
import white_print_icon from "../assets/images/white_print_icon.png";
import white_download_icon from "../assets/images/white_download_icon.png";
import white_zoom_icon from "../assets/images/white_zoom_icon.png";
import inner_logo from "../assets/images/inner_logo.png";

var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const OrderDetails = () => {
  const { id } = useParams();
  const { userDetails } = useContext(UserContext);
  const [review, setReview] = useState({ rating: 0, message: "" });
  const [show, setShow] = useState(false);
  const [zoomImage, setZoomImage] = useState(false);
  const [orderItemDetails, setOrderItemDetails] = useState("");
  // const [orderDetails, setOrderDetails] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show_two, setShow_two] = useState(false);
  const handleClose_two = () => setShow_two(false);
  const handleShow_two = () => setShow_two(true);
  // const getOrderData = async () => {
  //   const data = { order_id: location.state.itemDetails.order_id };
  //   const orderDetails = await axiosInstance.post("/order/get-order", data);
  //   setOrderDetails(orderDetails.data[0]);
  // };
  const getOrderItem = async () => {
    let res = await axiosInstance.get(`/order/item/${id}`);
    var date = new Date(res.data[0].created_at);
    var month = months[date.getMonth()];
    var day = date.getDate();
    var year = date.getFullYear();
    res.data[0].created_at = `${month} ${day}, ${year}`;
    setOrderItemDetails(res.data[0]);
    getOrderItemsByOrderId(res.data[0]?.order_id);
  };
  const handleCancleOrder = async () => {
    const res = await axiosInstance.put("/order/cancel/" + id);
    if (res.data.message === "Deleted Successfully") {
      swal({
        title: "Order Cancelled !",
        text: "Your order is successfully cancelled.",
        icon: "success",
        button: "Ok",
      });
      getOrderItem();
    }
  };
  const handleDownload = () => {
    downloadImage();
  };
  const handleReview = (value, id) => {
    if (id === "rating") {
      setReview({ ...review, rating: value });
    } else {
      setReview({ ...review, message: value });
    }
  };
  const handleSubmit = async () => {
    try {
      if (review.rating === 0) {
        alert("Oppsss you might be forgot to give rating !!!");
      } else {
        await axiosInstance.post("/order/item/review", {
          ...review,
          order_item_id: orderItemDetails.order_item_id,
        });

        swal({
          title: "Thank You !",
          text: "We received your feedback.",
          icon: "success",
          button: "Ok",
        }).then((res) => setShow(false));
      }
    } catch (error) {
      swal({
        title: "Something went wrong!",
        text: "Please try again",
        icon: "error",
        timer: 3000,
      }).then((res) => setShow(false));
    }
  };
  const downloadImage = () => {
    saveAs(orderItemDetails.design_link, "file.dxf"); // Put your image url here.
  };
  const handleZoomImage = () => {
    setZoomImage(!zoomImage);
  };
  const getOrderItemsByOrderId = async (orderId) => {
    try {
      await axiosInstance.get(`/order/items/${orderId}`);
    } catch (error) {}
  };
  useEffect(() => {
    getOrderItem();
  }, []);
  return (
    userDetails && (
      <LayoutInner>
        <div className="innerpage faq_page">
          <div className="container">
            <div className="breadcrumb_container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Home">
                      <img src={breadcrumb_home_icon} loading="lazy" alt="" />
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/myOrder">My Order</Link>
                  </li>
                  <li className="breadcrumb-item">Order Details</li>
                </ol>
              </nav>
            </div>
            <div className="teable_head">
              <div className="teable_head_left">
                <Link className="left_link" to="">
                  <img src={left_link_icon} loading="lazy" alt="" />
                </Link>
                <h1>Order Details</h1>
              </div>
              <div className="teable_head_right"></div>
            </div>

            <div className="teable_body orderDetails_body">
              <ul className="order_on">
                <li>
                  Ordered on <span>{orderItemDetails?.created_at}</span>
                  {orderItemDetails?.cancelled === 1 ? (
                    <span className="order__cancelled">
                      (Order item is cancelled)
                    </span>
                  ) : orderItemDetails?.order_delivered === 1 ? (
                    <span className="order__delivered">
                      (Order item is delivered)
                    </span>
                  ) : null}
                </li>
                <li>
                  Order Id # <span>{orderItemDetails?.order_id}</span>
                </li>
              </ul>

              <div className="order_details_address">
                <div className="">
                  <h4>Shipping Address</h4>
                  <ul>
                    <li>
                      <img src={address_icon} loading="lazy" alt="" />
                    </li>
                    <li>{orderItemDetails?.shipping_address}</li>
                  </ul>
                </div>
                <div className="contact_details">
                  <h4>Contact Details</h4>
                  <ul>
                    <li>
                      <img src={name_icon} loading="lazy" alt="" />
                    </li>
                    <li>
                      {orderItemDetails?.first_name}{" "}
                      {orderItemDetails?.last_name}
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <img src={phone_icon} loading="lazy" alt="" />
                    </li>
                    <li>{orderItemDetails?.contact_number}</li>
                  </ul>
                  <ul>
                    <li>
                      <img src={mail_icon_icon} loading="lazy" alt="" />
                    </li>
                    <li className="email">{orderItemDetails?.email}</li>
                  </ul>
                </div>
                <div className="shipping_address">
                  <div className="">
                    <h4>Billing Address</h4>
                    <ul>
                      <li>
                        <img src={address_icon} loading="lazy" alt="" />
                      </li>
                      <li>{orderItemDetails?.billing_address}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="pending_wrapper">
                <h2>{orderItemDetails?.status}</h2>
                {/* Expected Delivery on 20 April 2023 */}
              </div>

              <ul className="order_details_product">
                <li className="box_one">
                  <div className="box_one_content">
                    <div className="product_image_function_box">
                      <div className="first">
                        <img
                          src={white_print_icon}
                          loading="lazy"
                          alt=""
                          onClick={() => window.print()}
                        />
                        <img
                          src={white_download_icon}
                          loading="lazy"
                          alt=""
                          onClick={downloadImage}
                        />
                      </div>
                      <div>
                        <img
                          src={white_zoom_icon}
                          loading="lazy"
                          alt=""
                          onClick={handleZoomImage}
                        />
                      </div>
                    </div>
                    <img
                      src="http://14.140.119.44/biswajit-das/CuttingCentreBackend/uploads/1675100502010-671067354-1200x1200 LINEAR METER.dxf"
                      loading="lazy"
                      alt=""
                    />
                  </div>
                </li>

                <li className="box_two">
                  <div className="product_details">
                    <ul>
                      <li>Material</li>
                      <li>{orderItemDetails?.material_name}</li>
                    </ul>
                    <ul>
                      <li>Thickness</li>
                      <li>{orderItemDetails?.thickness}</li>
                    </ul>
                    <ul>
                      <li>Color</li>
                      <li>{orderItemDetails?.color_name}</li>
                    </ul>
                    <ul>
                      <li>Finish</li>
                      <li>{orderItemDetails?.finish_name}</li>
                    </ul>
                    <ul>
                      <li>Quantity</li>
                      <li>{orderItemDetails?.quantity}</li>
                    </ul>
                    {/* <ul>
                    <li>Product Details</li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Morbi non erat ex. Nam auctor vel tellus et cursus.
                    </li>
                  </ul> */}
                  </div>
                </li>

                <li className="box_three">
                  <button
                    className="green_button"
                    type="button"
                    onClick={handleDownload}
                  >
                    Download Your Uploaded File
                  </button>
                  <button className="green_button" onClick={handleShow_two}>
                    View invoice
                  </button>
                  <button className="green_button" onClick={handleShow}>
                    Write a Product Review
                  </button>
                  {orderItemDetails?.cancelled ===
                  1 ? null : orderItemDetails?.order_delivered === 1 ? null : (
                    <button className="red_button" onClick={handleCancleOrder}>
                      Cancel Order
                    </button>
                  )}
                </li>
              </ul>
            </div>
          </div>

          {/* Invoice Modal */}

          <Modal
            show={show_two}
            onHide={handleClose_two}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="rateing_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Tax Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="table-responsive">
                <div className="table_box">
                  <table class="table table-borderless head">
                    <tbody>
                      <tr
                        class=""
                        // style={{
                        //   marginTop: "-100px",
                        //   justifyContent: "center",
                        //   alignItems: "center",
                        // }}
                      >
                        <td>
                          <p className="add">Bill From </p>
                          <p>
                            Blue Rhine Industries L.L.C P.O Box No: 114001,Plot
                            597-673, Dubai Investment Park- 2 Dubai United Arab
                            Emirates.
                          </p>
                          <p>TRN: 100011246400003</p>
                          {/* <p style={{ textAlign: "left" }}>
                            Blue Rhine Industries L.L.C P.O Box No: 114001,Plot
                            597-673, Dubai Investment Park- 2 Dubai United Arab
                            Emirates.
                          </p> */}
                          {/* <p style={{ textAlign: "left" }}>
                            TRN: 100011246400003
                          </p> */}
                        </td>

                        <td className="">
                          <p>&nbsp;</p>
                          <img src={inner_logo} loading="lazy" alt="" />
                        </td>
                      </tr>
                      <tr class="content">
                        <td class="right">
                          {/* <br /> */}
                          {/* <img src={inner_logo} loading="lazy" alt="" /> */}

                          {/* <p style={{ textAlign: "left" }}>
                            Blue Rhine Industries L.L.C P.O Box No: 114001,Plot
                            597-673, Dubai Investment Park- 2 Dubai United Arab
                            Emirates.
                          </p>
                          <p style={{ textAlign: "left" }}>
                            TRN: 100011246400003
                          </p> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <table class="table table-borderless head">
                    <tbody>
                      <tr class="add">
                        <td className="">Billing to</td>
                        <td></td>
                      </tr>
                      <tr class="content">
                        <td class="main_table_left">
                          {orderItemDetails?.billing_address}
                        </td>
                        <td class="right right_table main_table_right">
                          <table class="table table-borderless">
                            <tr>
                              <td className="left">Invoice#</td>
                              <td className="right">
                                INV-TCC-{orderItemDetails.order_item_id}
                              </td>
                            </tr>
                            <tr>
                              <td className="left">Invoice date</td>
                              <td className="right">
                                {orderItemDetails.created_at}
                              </td>
                            </tr>
                            {/* {orderItemDetails?.actual_price && (
                              <tr>
                                <td className="left">Price</td>
                                <td className="right">
                                  AED {orderItemDetails.actual_price}
                                </td>
                              </tr>
                            )}
                            {orderItemDetails?.vat_price && (
                              <tr>
                                <td className="left">VAT</td>
                                <td className="right">
                                  AED {orderItemDetails.vat_price}
                                </td>
                              </tr>
                            )} */}
                            <tr className="">
                              {/* <td className="invoice_td_bg left">Amount Due</td>
                              <td className="right textBold invoice_td_bg">
                                {`${orderItemDetails.selling_price}`}
                              </td> */}
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-borderless head">
                    <tr>
                      <td className="table_pad_LR">
                        <table class="table table-borderless head">
                          <tbody>
                            <tr class="content">
                              <td className="textBold invoice_td_bg right">
                                Material Name
                              </td>
                              <td className="textBold invoice_td_bg right">
                                Thickness
                              </td>
                              <td className="textBold invoice_td_bg center">
                                Quantity
                              </td>
                              <td className="textBold invoice_td_bg">
                                Unit Cost
                              </td>
                              <td className="textBold invoice_td_bg">
                                Total Cost
                              </td>
                            </tr>

                            <tr class="content bottom_line">
                              <td className="center">
                                {orderItemDetails.material_name}
                              </td>
                              <td className="center">
                                {orderItemDetails.thickness}
                              </td>
                              <td className="center">
                                {orderItemDetails.quantity}
                              </td>
                              <td className="">
                                {(
                                  orderItemDetails.selling_price /
                                  orderItemDetails.quantity
                                ).toFixed(2)}
                              </td>
                              <td className="">
                                {orderItemDetails.selling_price}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>

                  <table class="table table-borderless head bottom_line">
                    <tbody>
                      <tr class="add">
                        {/* <td className="">NOTES</td> */}
                        <td></td>
                      </tr>
                      <tr class="content">
                        {/* <td class="main_table_left">
                          Free shipping with 30-day money-back guarantee
                        </td> */}
                        <td class="main_table_left"></td>
                        <td class="right right_table main_table_right">
                          <table class="table table-borderless">
                            {orderItemDetails?.actual_price && (
                              <tr>
                                <td className="left">SUBTOTAL</td>
                                <td className="right">
                                  AED {orderItemDetails.actual_price}
                                </td>
                              </tr>
                            )}
                            {orderItemDetails?.vat_price && (
                              <tr>
                                <td className="left">TAX</td>
                                <td className="right">
                                  AED {orderItemDetails.vat_price}
                                </td>
                              </tr>
                            )}
                            <tr className="">
                              <td className="invoice_td_bg left">TOTAL</td>
                              <td className="right textBold invoice_td_bg">
                                AED {`${orderItemDetails.selling_price}`}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="footer_table">
                <p className="center">
                  <img src={inner_logo} loading="lazy" alt="" />
                </p>
                <p className="center">
                  This is a system generated document and hence does not require
                  signature
                </p>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
                    <Button className="loginButton_active" variant="primary">Suibmit</Button>
                    </Modal.Footer> */}
          </Modal>

          {/* Review Modal */}

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="rateing_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Leave Review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul>
                <li className="heading">Rate this item</li>

                <li style={{ height: "100%" }}>
                  <div>
                    <ReactStars
                      count={5}
                      onChange={(value) => handleReview(value, "rating")}
                      size={25}
                      activeColor="#ffd700"
                    />
                  </div>
                </li>

                <li>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="heading">
                      How was your overall experience using the item?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      onChange={(e) => handleReview(e.target.value, "message")}
                    />
                  </Form.Group>
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button className="gray_active" variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button> */}
              <Button
                className="loginButton_active"
                variant="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/** Zoom Image Modal */}
        <Modal
          show={zoomImage}
          onHide={handleZoomImage}
          // keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img
              src={orderItemDetails?.png_link}
              alt="none"
              style={{ maxWidth: "100%" }}
            />
          </Modal.Body>
        </Modal>
      </LayoutInner>
    )
  );
};

export default OrderDetails;
