import React, { useState, useContext } from "react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { TextField, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link as MuiLink, Typography } from "@mui/material";

import { UserContext } from "../../context/userContextProvider";
import { AuthContext } from "../../context/authContextProvider";
import axiosInstance from "../../helpers/axios";

import login_logo from "../assets/images/login_logo.png";

const SignIn = () => {
  const { isAuth, toggleAuth } = useContext(AuthContext);
  const { toggleUser } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // const showPassword = () => {
  //   setShow((prev) => !prev);
  // };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleLogin = () => {
    const data = {
      email: email,
      password: password,
    };
    axiosInstance
      .post("/user/login", data)
      .then((res) => {
        if (res.data.msg === "Wrong Password!") {
          swal({
            title: "Wrong Password!",
            text: "Please check your password.",
            icon: "warning",
            buttons: {
              roll: {
                className: "signin-error-modal-btn",
                text: "Ok",
                value: "ok",
              },
            },
          });
        }
        localStorage.setItem("user-token", res.data.token);
        localStorage.setItem("isLogin", true);
        toggleAuth(res.data.token);
        toggleUser(res.data);
      })
      .catch((err) => {
        if (err.status === 404) {
          swal({
            title: "USER NOT FOUND",
            text: "Please sign up first.",
            icon: "warning",
            buttons: {
              roll: {
                className: "signin-error-modal-btn",
                text: "Sign Up",
                value: "signup",
              },
            },
          }).then((res) => res === "signup" && navigate("/signup"));
        } else if (err.status === 401) {
          Swal.fire({
            title: "Wrong credentials!",
            text: "Invalid email or password",
            icon: "warning",
            confirmButtonText: "Okay",
            timer: 6000,
          });
        }
      });
  };
  if (isAuth) {
    navigate("/home");
  }
  return (
    <>
      <div className="login_outer">
        <section>
          <div className="container">
            <div className="row loginBox">
              <div className="col-md-6 loginBox_left">
                <img src={login_logo} className="" loading="lazy" alt="" />
                {/* <p className="middle_loginBox">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p> */}
                <p className="footer_loginBox">
                  All Rights Reserved © 2023 Blue Rhine
                </p>
              </div>
              <div className="col-md-6">
                <div className="wrapper_login">
                  <div className="heading">
                    <h1>Welcome</h1>
                    <p>Sign in to your account to continue</p>
                  </div>

                  <Form>
                    <div className="form-floating mb-3">
                      <TextField
                        label="Email"
                        placeholder="name@example.com"
                        type="email"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        variant="outlined"
                        required
                      />
                      {/* <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label for="floatingInput">Email address</label> */}
                    </div>
                    <div className="form-floating input-group">
                      <TextField
                        label="Password"
                        placeholder="...."
                        type={showPassword ? "text" : "password"}
                        defaultValue={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={handleTogglePassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                        required
                      />
                      {/* <input
                        type={show ? "text" : "password"}
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <label for="floatingPassword">Password</label> */}
                    </div>
                    {/* <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      className="pw_view_button"
                      onClick={showPassword}
                    >
                      <img
                        src={show ? view_icon : pw_view_logo}
                        className=""
                        loading="lazy"
                        alt=""
                      />
                    </Button> */}
                    {/* <input type="checkbox" onClick={showPassword} />{" "}
                    <label>Show Password</label> */}
                    <div className="forgot_pw">
                      <Typography>
                        Forgot your password?{" "}
                        <MuiLink href="/forgot-password" color="primary">
                          Reset it here
                        </MuiLink>
                      </Typography>
                      {/* <label>Forgot Your Password?</label> */}
                    </div>
                    <div className="button_group">
                      <Button
                        variant="primary"
                        className="loginButton_active"
                        onClick={() => handleLogin()}
                      >
                        Login
                      </Button>

                      <p>
                        Don't have an account?{" "}
                        <Link to="/signup">Sign Up!</Link>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SignIn;
