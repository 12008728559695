import React, {Component, Fragment } from 'react';

import HeaderInner from '../HeaderInner/HeaderInner';
import FooterInner from '../FooterInner/FooterInner';

export class LayoutInner extends Component {
   

    render() {
        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="container-fluid1">
                        <div className="card_body">
                            <HeaderInner />
                                {this.props.children}
                            <FooterInner />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default LayoutInner
