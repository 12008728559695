import React from "react";
import { Button, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
const AddressEditModal = ({
  show,
  handleClose,
  address,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="form-floating mb-3">
            <TextField
              id="address"
              label="Address"
              type="text"
              defaultValue={address?.address}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              helperText="Please ensure that the shipping address provided is located within the UAE region."
              required
            />
          </div>
        </div>
        <Button onClick={handleSubmit}>Save</Button>
      </Modal.Body>
    </Modal>
  );
};

export default AddressEditModal;
