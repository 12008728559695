import React from "react";
import styles from "./ApiLoader.module.css";

const ApiLoader = () => {
  return (
    <div className={styles.dot__wave}>
      <div className={styles.dot__wave__dot}></div>
      <div className={styles.dot__wave__dot}></div>
      <div className={styles.dot__wave__dot}></div>
      <div className={styles.dot__wave__dot}></div>
    </div>
  );
};

export default ApiLoader;
