import React from "react";
import Layout from "../Layout/Layout";

const Dashboard = () => {
  return (
    <Layout>
      <div className="row">
        <div className="col">Dashboard</div>
      </div>
    </Layout>
  );
};

export default Dashboard;
