import { createContext, useState } from "react";

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState("");
  const [userProfileDetails, setUserProfileDetails] = useState("");
  const [userProfile, setUserProfile] = useState("");

  const toggleUser = (data, temp) => {
    setUserDetails(data);
  };
  const toggleUserProfileDetails = (data) => {
    setUserProfileDetails(data);
  };
  const toggleUserProfile = (data) => {
    setUserProfile(data);
  };
  const value = {
    userDetails,
    toggleUser,
    toggleUserProfileDetails,
    userProfileDetails,
    toggleUserProfile,
    userProfile,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
