import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Navigation } from "swiper";
import { FreeMode, Thumbs } from "swiper";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
// loader package
import { ThreeDots } from "react-loader-spinner";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { v4 as uuid } from "uuid";

// components
import LayoutInner from "../LayoutInner/LayoutInner";
// context
import { UserContext } from "../../context/userContextProvider";
// helper
import API from "../../helpers/axios";

// images
import breadcrumb_home_icon from "../assets/images/breadcrumb_home.png";
import rigid_01 from "../assets/images/rigid_01.png";
import rigid_02 from "../assets/images/rigid_02.png";
import rigid_03 from "../assets/images/rigid_03.png";
import rigid_04 from "../assets/images/rigid_04.png";
import rigid_th_01 from "../assets/images/rigid_th_01.png";
import rigid_th_02 from "../assets/images/rigid_th_02.png";
import rigid_th_03 from "../assets/images/rigid_th_03.png";
import rigid_th_04 from "../assets/images/rigid_th_04.png";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/thumbs/thumbs.min.css";

const RigidSubstrates = () => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(false);
  const [quoteLoader, setQuoteLoader] = useState(false);
  const [orderLoader, setOrderLoader] = useState(false);
  const [priceErrorLoader, setPriceErrorLoader] = useState(false);
  const [isUserProvideMaterial, setIsUserProvideMaterial] = useState("");
  const [materials, setMaterials] = useState("");
  const [thickness, setThickness] = useState([]);
  const [colors, setColors] = useState([]);
  const [finish, setFinish] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [formSubmited, setFormSubmited] = useState(false);
  const [data, setData] = useState("");
  const [singlePrice, setSinglePrice] = useState(null);
  const [price, setPrice] = useState("");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [manual, setManual] = useState(false);
  const [getAQuoteLoader, setGetAQuoteLoader] = useState(false);
  const [quote, setQuote] = useState({});

  const saveFile = async (e) => {
    try {
      var fileName = e.target.files[0].name;
      // Regular expression for file extension.
      var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      //Get the file Extension
      var fileExtension = fileName.match(patternFileExtension);
      setProductDetails({
        ...productDetails,
        imagePreview: URL.createObjectURL(e.target.files[0]),
        image: e.target.files[0],
      });

      if (fileExtension[1] === "eps" || fileExtension[1] === "ai") {
        setManual(true);
        const formData = new FormData();
        formData.append("photo", e.target.files[0]);
        formData.append("userId", userDetails.user.user_id);
        await API.post("/order/manual", formData);
        swal({
          title: "Thank You !",
          text: "We will contact you by email.",
          icon: "success",
          button: "Ok",
        }).then((res) => navigate("/home"));
      } else {
        if (e.target.files[0]) {
          const formData = new FormData();
          formData.append("photo", e.target.files[0]);
          setFormSubmited(false);
          setLoader(true);
          setData("");

          const res = await axios.post(
            `${process.env.REACT_APP_PYTHON_BACKEND_URL}`,
            formData,
            { headers: { "Access-Control-Allow-Origin": "*" } }
          );
          const { data } = res;
          setManual(false);
          setData(data);
          setLoader(false);
        }
      }
    } catch (error) {
      setLoader(false);
      Swal.fire({
        title: "We got an error.",
        text: "Check your design and file type or upload another file.",
        icon: "warning",
        confirmButtonText: "Okay",
      });
    }
  };
  const getThicknessByMaterialId = async (id) => {
    const { data } = await API.get(`/thickness?materialId=${id}`);
    setThickness(data);
  };
  const getColorByMaterialId = async (id) => {
    const { data } = await API.get(`/color?materialId=${id}`);
    setColors(data);
  };
  const getFinishByMaterialId = async (id) => {
    const { data } = await API.get(`/finish?materialId=${id}`);
    setFinish(data);
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name === "material_id") {
      await getThicknessByMaterialId(value);
      await getColorByMaterialId(value);
      await getFinishByMaterialId(value);
    }
    setProductDetails({ ...productDetails, [name]: value });
  };
  const getMaterials = () => {
    API.get("/material/all").then((res) => setMaterials(res.data));
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setProductDetails({ ...productDetails, id: uuid() });
      if (manual) {
        setGetAQuoteLoader(true);
        const formData = new FormData();
        formData.append("price", 0);
        formData.append("price_for_one", 0);
        formData.append("status", "under_review");
        formData.append("photo", productDetails.image);
        formData.append("userId", userDetails.user.user_id);
        formData.append("png_link", data.png);
        formData.append("in_cart", "false");
        //formData.append("image_link", "http://14.140.119.44/biswajit-das/CuttingCentreBackend/uploads/1675142919677-798122193-pattern - 1.ai")
        Object.keys(productDetails).forEach((key) => {
          if (key !== "image") {
            formData.append(key, productDetails[key]);
          }
        });
        Object.keys(data).forEach((key) => {
          if (key !== "image") {
            formData.append("image_" + key, data[key]);
          } else if (key === "circumference") {
            const circumference = Math.round(data[key]);
            formData.append("image_" + key, circumference);
          }
        });

        const response = await API.post("/order/createOrderItems", formData);
        setGetAQuoteLoader(false);
        if (response.status === 200) {
          swal({
            title: "Thank You !",
            text: "We will contact you by email within 24 hours.",
            icon: "success",
            button: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      } else {
        getCuttingPrice(data);
      }
    } catch (err) {}
  };
  const getCuttingPrice = (data) => {
    const formData = new FormData();
    formData.append("photo", productDetails.image);
    formData.append("userId", userDetails.user.user_id);
    formData.append("isUserProvideMaterial", isUserProvideMaterial);
    Object.keys(productDetails).forEach((key) => {
      if (key !== "image") {
        formData.append(key, productDetails[key]);
      }
    });
    Object.keys(data).forEach((key) => {
      if (key !== "image") {
        formData.append("image_" + key, data[key]);
      } else if (key === "circumference") {
        const circumference = Math.round(data[key]);
        formData.append("image_" + key, circumference);
      }
    });
    setPriceErrorLoader(false);
    setQuoteLoader(true);
    const payload = {
      ...productDetails,
      height: data.height,
      width: data.width,
      circumference: data.circumference,
      isUserProvideMaterial: isUserProvideMaterial,
    };
    API.post("/quote", payload)
      .then((res) => res.data)
      .then((response) => {
        if (
          response.sellingPrice === null ||
          response.selling_price_for_one === null ||
          response.selling_price === "" ||
          response.selling_price === " "
        ) {
          priceErrorLoader(true);
        } else {
          setQuote(response);
          setPrice(response.sellingPrice);
          console.log(
            "Total square meter to cut is : " + response.totalCuttingSqrMtrs
          );
          setSinglePrice(response.selling_price_for_one);
          setFormSubmited(true);
          setQuoteLoader(false);
        }
      });
  };
  const createOrder = () => {
    const data = {
      userId: userDetails.user.user_id,
    };
    setOrderLoader(true);
    API.post("/order/createOrder", data)
      .then((res) => res.data)
      .then((response) => {
        localStorage.setItem("order-session", response.token);
        createOderDetails(response.token);
        setOrderLoader(false);
      });
  };
  const createOderDetails = async () => {
    setOrderLoader(true);
    try {
      const payload = {
        selling_price: price,
        actual_price: quote.taxableValue,
        vat_price: quote.vat,
        vat_percentage: quote.vatPercentage,
        price_for_one: singlePrice,
        status: "pending",
        userId: userDetails.user.user_id,
        inCart: "true",
      };
      // const formData = new FormData();
      // formData.append("price", price);
      // formData.append("price_for_one", singlePrice);
      // formData.append("status", "pending");
      // formData.append("photo", productDetails.image);
      // formData.append("userId", userDetails.user.user_id);
      // formData.append("png_link", data.png);
      // formData.append("inCart", "true");
      //formData.append("image_link", "http://14.140.119.44/biswajit-das/CuttingCentreBackend/uploads/1675142919677-798122193-pattern - 1.ai")
      Object.keys(productDetails).forEach((key) => {
        if (key !== "image") {
          payload[key] = productDetails[key];
        }
      });
      Object.keys(data).forEach((key) => {
        if (key !== "image") {
          payload[key] = data[key];
        } else if (key === "circumference") {
          payload[key] = data[key];
        }
      });

      await API.post("/cart/add", payload);
      setOrderLoader(false);
      navigate("/ShoppingCart");
    } catch (err) {
      localStorage.removeItem("order-session");
      createOrder();
    }
  };
  const handleAddToCart = async () => {
    createOderDetails();
  };
  const handleReset = async () => {
    window.location.reload();
  };
  useEffect(() => {
    handleShow();
    getMaterials();
  }, []);
  return (
    <LayoutInner>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="rigid_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Important Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>We only accept Dxf file.</li>
            <li>
              We follow a mechanism to auto-read your file and auto-estimate, to
              get you an instant quotation, to save you time and help you with
              quick decision.
            </li>
            <li>The estimate shall not be accurate for any other versions.</li>
            <li>
              In any case, where the estimate is not found to be accurate due to
              version change or any other reasons, we shall get back to you by
              email or phone to explain and adjust the estimate, if payment has
              already been made.
            </li>
            <li>
              Please validate the Linear Meter shown after file upload to ensure
              accuracy before you move on to 'Get A Quote'.
            </li>
            <li>
              Print size in a single cut file should be within a limit of 2440mm
              x 1220mm. If you have any higher sizes, we recommend you split
              them into multiple cut-files and upload them through multiple
              enquiries/estimate.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="loginButton_active"
            variant="primary"
            onClick={handleClose}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="innerpage Rigid_page">
        <div className="container">
          <div className="breadcrumb_container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/Home">
                    <img src={breadcrumb_home_icon} loading="lazy" alt="" />
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">Rigid Substrates</li>
              </ol>
            </nav>
          </div>
          <div className="teable_head">
            <div className="teable_head_left">
              <h1>Rigid Substrates</h1>
            </div>
            <div className="teable_head_right"></div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="teable_body rigdDetails_body">
              <div className="left">
                <div className="swiper_cover">
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    loop={true}
                    spaceBetween={2}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                  >
                    <SwiperSlide>
                      <img src={rigid_01} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_02} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_03} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_04} loading="lazy" alt="" />
                    </SwiperSlide>
                  </Swiper>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    spaceBetween={2}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <img src={rigid_th_01} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_th_02} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_th_03} loading="lazy" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={rigid_th_04} loading="lazy" alt="" />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <div className="right">
                <div className="rigdDetails_right_content custom_select_form">
                  <p className="upload_file_top_text">
                    It is simple. Choose options and upload your design. Click
                    on 'Get A Quote' to see your price instantly. We do not
                    require your entire design file but the cut-file. Your
                    cut-file in dxf format makes our price calculation accurate.
                  </p>
                  <p className="upload_file_top_text">
                    While presently we can process .dxf and .dwg files only, you
                    may also provide us with uploads for .ai and .eps files.
                    Please give us 24 working hours to return to you with a
                    quotation for the required cutting.
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        name="image"
                        accept=".dxf,.dwg,.eps,.ai,.cdr"
                        //src={state && state.design_link}
                        onChange={saveFile}
                        required
                      />

                      {/* <div className="">
                        <p
                          for="formFile"
                          className="form-label upload_file_label_text"
                        >
                          Upload Drawing
                        </p>
                        <div className="file_upload_cover">
                          <p className="upload_file_name"></p>
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                            name="image"
                            accept=".dxf,.dwg,.eps,.ai,.cdr"
                            onChange={saveFile}
                          />
                          <img src={upload_icon} loading="lazy" alt="" />
                        </div>
                        <p className="form_small_text">
                          *Please upload cut-file drawing in dxf format
                        </p>
                      </div> */}
                    </div>
                    <div className="col-lg-6">
                      <Form.Group
                        className="mt-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Material will be provided by me"
                          onChange={(e) =>
                            setIsUserProvideMaterial(e.target.checked)
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {loader ? (
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <>
                      <div className="row">
                        {!manual && (
                          <div className="col-lg-6 from_row_gap">
                            <p
                              for="formFile"
                              className="form-label upload_file_label_text"
                            >
                              Linear-meters of your drawing in Meter.
                            </p>
                            <input
                              className="form-control custom_select"
                              aria-label="Default  example"
                              defaultValue={
                                data
                                  ? Math.round(data.circumference) / 1000
                                  : null
                              }
                              disabled
                            ></input>
                          </div>
                        )}
                        <div className="col-lg-6 from_row_gap">
                          <p
                            for="formFile"
                            className="form-label upload_file_label_text"
                          >
                            Choose Material
                          </p>
                          <select
                            className="form-select custom_select"
                            aria-label="Default select example"
                            name="material_id"
                            required
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="" disabled selected>
                              Choose Material
                            </option>
                            {materials &&
                              materials.map((item) => {
                                return (
                                  <option value={item.material_id}>
                                    {item.material_name}
                                  </option>
                                );
                              })}

                            {/* <option selected>Acrylic</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option> */}
                          </select>
                        </div>
                        {/* {machines.length > 0 && (
                          <div className="col-lg-6 from_row_gap">
                            <p
                              for="formFile"
                              className="form-label upload_file_label_text"
                            >
                              Choose Machine
                            </p>
                            <select
                              className="form-select custom_select"
                              aria-label="Default select example"
                              name="machine_id"
                              required
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="" disabled selected>
                                Choose Machine
                              </option>
                              {machines?.map((machine) => (
                                <option value={machine.machine_id}>
                                  {machine.machine_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )} */}
                        {thickness.length > 0 && (
                          <div className="col-lg-6 from_row_gap">
                            <p
                              for="formFile"
                              className="form-label upload_file_label_text"
                            >
                              Choose Thickness
                            </p>
                            <select
                              className="form-select custom_select"
                              aria-label="Default select example"
                              name="thickness_id"
                              required
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="" disabled selected>
                                Choose Thickness
                              </option>
                              {thickness?.map((item) => (
                                <option value={item.thickness_id}>
                                  {item.thickness} mm
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        {colors.length > 0 && (
                          <div className="col-lg-6 from_row_gap">
                            <p
                              for="formFile"
                              className="form-label upload_file_label_text"
                            >
                              Choose Color
                            </p>
                            <select
                              className="form-select custom_select"
                              aria-label="Default select example"
                              name="color_id"
                              onChange={(e) => handleChange(e)}
                              required
                            >
                              <option value="" disabled selected>
                                Choose Color
                              </option>
                              {colors.map((color) => (
                                <option value={color.color_id}>
                                  {color.color_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        {finish.length > 0 && (
                          <div className="col-lg-6 from_row_gap">
                            <p
                              for="formFile"
                              className="form-label upload_file_label_text"
                            >
                              Choose Finish
                            </p>
                            <select
                              className="form-select custom_select"
                              aria-label="Default select example"
                              name="finish_id"
                              onChange={(e) => handleChange(e)}
                              required
                            >
                              <option value="" disabled selected>
                                Choose Finish
                              </option>
                              {finish?.map((item) => (
                                <option value={item.finish_id}>
                                  {item.finish_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        <div className="col-lg-6 from_row_gap">
                          <p
                            for="formFile"
                            className="form-label upload_file_label_text"
                          >
                            Quantity
                          </p>
                          <input
                            type="number"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            min="1"
                            name="quantity"
                            onChange={(e) => handleChange(e)}
                            required
                          />
                        </div>
                      </div>
                      {getAQuoteLoader ? (
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        <div className="button_group_normal">
                          {/* <Link to="/GetAquote" className="button_space"> */}
                          <Button
                            variant="primary"
                            type="submit"
                            // className="loginButton_active button_space"
                            className="loginButton_active button_space"
                          >
                            Get A Quote
                          </Button>
                          {/* </Link> */}

                          <Button
                            variant="primary"
                            className="gray_active"
                            onClick={handleReset}
                          >
                            Reset
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
          {quoteLoader === true ? (
            <ThreeDots
              height="80"
              width="100"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
              }}
              wrapperClassName=""
              visible={true}
            />
          ) : price === null || singlePrice === null ? (
            <div
              className={
                formSubmited
                  ? `${"add_to_cart_bottom_strip"}`
                  : `${"add_to_cart_bottom_strip_hide"}`
              }
            >
              <h3>
                Something went wrong, please click on{" "}
                <span className="bold-text">Get A Quote</span> again
              </h3>
            </div>
          ) : (
            <div
              className={
                formSubmited
                  ? `${"add_to_cart_bottom_strip"}`
                  : `${"add_to_cart_bottom_strip_hide"}`
              }
            >
              <div>
                <span className="price">Price:</span>{" "}
                <span className="price_value">AED {price}</span>{" "}
                <span className="vat">(Price inclusive of VAT)</span>
              </div>

              {orderLoader ? (
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{ marginLeft: "20px" }}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <Button
                  className="add_to_cart"
                  onClick={() => {
                    handleAddToCart();
                  }}
                >
                  Add to Cart
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </LayoutInner>
  );
};

export default RigidSubstrates;
