import React, { useEffect, useState } from "react";
import LayoutInner from "../LayoutInner/LayoutInner";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import axiosInstance from "../../helpers/axios";

import breadcrumb_home_icon from "../assets/images/breadcrumb_home.png";
import tick_basic_icon from "../assets/images/tick_basic.png";
import cart_delete_icon from "../assets/images/cart_delete_icon.png";

const ShoppingCart = () => {
  const [subTotal, setSubTotal] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const handleClick = async (str, item) => {
    if (str === "increase") {
      item["increased"] = 1;
      await axiosInstance.put("/cart/update", item);
      getCartItems();
    } else {
      item["decreased"] = 1;
      await axiosInstance.put("/cart/update", item);
      getCartItems();
    }
  };
  const handleCheckout = () => {
    navigate("/Checkout", {
      state: {
        subTotal: subTotal,
        totalPrice: totalPrice,
        vat: 60,
        shippingChrges: 50,
      },
    });
  };
  const handleDelete = async (order_item_id) => {
    await axiosInstance.delete(`/cart/delete/${order_item_id}`);
    getCartItems();
  };
  // const handleEdit = (item) => {
  //   navigate("/RigidSubstrates", {
  //     state: item,
  //   });
  // };

  const getCartItems = async () => {
    const token = localStorage.getItem("order-session");
    const config = {
      headers: {
        CreateOrder: `Bearer ${token}`,
      },
    };

    //const response = await axios.get("http://testmymobileapp.com:8000/order/cart-items", config);

    const response = await axiosInstance.get("/cart/all", config);
    const items = response.data;
    setData(items.data);
    setSubTotal(items.totalPrice);
    setTotalPrice(items.totalPrice);
  };

  useEffect(() => {
    getCartItems();
  }, []);
  return (
    <LayoutInner>
      <div className="innerpage Rigid_page">
        <div className="container">
          <div className="breadcrumb_container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/Home">
                    <img src={breadcrumb_home_icon} loading="lazy" alt="" />
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">Rigid Substrates</li>
              </ol>
            </nav>
          </div>

          <div className="teable_head">
            <div className="teable_head_left">
              <h1>Shopping cart</h1>
            </div>
            <div className="teable_head_right"></div>
          </div>

          <div className="shoppingCart_body">
            <div className="left">
              <div className="teable_body shoppingCart_body_left">
                <div className="cart_top_box">
                  <img src={tick_basic_icon} loading="lazy" alt="" /> You added{" "}
                  {data && data.length} designs to your shopping cart
                </div>
                {data &&
                  data?.map((item) => {
                    return (
                      <div className="cart_item_row">
                        <ul className="cart_item_row_boxes">
                          <li className="one">
                            <img
                              src={item.png_link}
                              height="100px"
                              width="120px"
                              loading="lazy"
                              alt=""
                            />
                          </li>
                          <li className="two">
                            <ul className="cart_item_row_boxes_inner">
                              <li>
                                <span>Material</span> {item.material_name}
                              </li>
                              <li>
                                <span>Thickness</span> {item.thickness}
                              </li>
                              <li>
                                <span>Color</span> {item.color_name}
                              </li>
                            </ul>
                          </li>
                          <li className="three">
                            <ul className="cart_item_row_boxes_inner">
                              <li>
                                <span>Finish</span> {item.finish_name}
                              </li>
                              <li>
                                <span>Quantity</span>
                                <ul className="plus_minus_cart">
                                  <li className="minus_cart">
                                    <button
                                      onClick={() => {
                                        handleClick("decrease", item);
                                      }}
                                    >
                                      {" "}
                                      -{" "}
                                    </button>
                                  </li>
                                  <li className="count_cart">
                                    {item.quantity}
                                  </li>
                                  <li className="plus_cart">
                                    <button
                                      onClick={() =>
                                        handleClick("increase", item)
                                      }
                                    >
                                      +{" "}
                                    </button>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <span>Price</span> AED {item.selling_price}
                              </li>
                            </ul>
                          </li>
                          <li className="four">
                            {/* <Button>
                              <img
                                src={cart_edit_icon}
                                loading="lazy"
                                alt=""
                                //onClick={() => handleEdit(item)}
                              />
                            </Button> */}
                            <Button
                              onClick={() => handleDelete(item.order_item_id)}
                            >
                              <img
                                src={cart_delete_icon}
                                loading="lazy"
                                alt=""
                              />
                            </Button>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="right">
              <div className="cart_summary">
                <h3>Summary</h3>
                <ul>
                  <li>Subtotal</li>
                  <li>AED {subTotal}</li>
                </ul>
                {/* <ul>
                  <li>Vat</li>
                  <li>AED 60</li>
                </ul> */}
                <ul className="total">
                  <li>Total</li>
                  <li>AED {totalPrice}</li>
                </ul>

                {/* <select className="cart_select">
                  <option selected>Apply Discount Code</option>
                </select> */}

                <Button
                  className="cart_button"
                  onClick={() => handleCheckout()}
                  disabled={data?.length === 0 ? true : false}
                >
                  Go to Checkout
                </Button>

                {/* <p className="mul_add">
                  <Link to="">Checkout with multiple address</Link>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutInner>
  );
};

export default ShoppingCart;
