import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

import fb_icon from "../assets/images/fb_icon.png";
import insta_icon from "../assets/images/insta_icon.png";
import youtube_icon from "../assets/images/youtube_icon.png";

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleReturnAndRefund = () => {
    setShow(true);
  };
  return (
    <div className="footer_outer">
      <div className="container">
        <div className="footer">
          <div className="footer_left">
            All Rights Reserved © 2023 Blue Rhine
          </div>

          <div className="footer_right">
            <ul>
              <li>
                <Link className="footer_right_link" to="/Contact">
                  Contact Us
                </Link>
                <Link className="footer_right_link" to="">
                  Privacy and Cookie Policy
                </Link>
                <Link
                  className="footer_right_link"
                  to=""
                  onClick={handleReturnAndRefund}
                >
                  Returns & Refunds
                </Link>
              </li>
              <li>
                <Link to="">
                  <img
                    src={fb_icon}
                    className="home_logo"
                    loading="lazy"
                    alt=""
                  />
                </Link>
                <Link to="">
                  <img
                    src={insta_icon}
                    className="home_logo"
                    loading="lazy"
                    alt=""
                  />
                </Link>
                <Link to="">
                  <img
                    src={youtube_icon}
                    className="home_logo"
                    loading="lazy"
                    alt=""
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title className="popup-title">
            Return and Refund Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="popup-text">
            If you need to request a return or refund, please don't hesitate to
            reach out to our customer support team. We're here to assist you.
          </p>
          <p className="popup-email">
            Contact us at{" "}
            <a
              href="mailto:support@thecuttingcenter.com"
              className="email-link"
            >
              support@thecuttingcenter.com
            </a>{" "}
            for prompt assistance.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShow(false)}
            className="popup-close-button"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Footer;
