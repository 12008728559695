import React, { useEffect, useState } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { TextField, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import axiosInstance from "../../helpers/axios";

import login_logo from "../assets/images/login_logo.png";

const Signup = () => {
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    contactNumber: "",
    // emirate: "",
    // city: "",
    // area: "",
    // street: "",
    // building: "",
    // apartment: "",
    address: "",
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (validateCaptcha(captcha) === true) {
      const data = {
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        email: userDetails.email,
        password: userDetails.password,
        confirm_password: userDetails.confirmPassword,
        contact_number: userDetails.contactNumber,
        address: userDetails.address,
      };
      if (userDetails.password !== userDetails.confirmPassword) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Passwords mismatched!",
          confirmButtonText: "Okay",
          timer: 3000,
        });
      } else {
        axiosInstance
          .post("/user/signup", data)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: res?.data ? "Account Created Successfully" : "",
              confirmButtonText: "Okay",
              timer: 4000,
            }).then(() => navigate("/signin"));
          })
          .catch((err) => {
            if (err.status === 409) {
              alert("User already exist, Login or click on forgot password");
              navigate("/signin");
            } else {
              alert("Oops...Something Wen Wrong!");
            }
          });
      }
    } else {
      alert("Captcha Does Not Match");
      loadCaptchaEnginge(6);
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setUserDetails({ ...userDetails, [id]: value });
  };

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  return (
    // <Layout>
    <>
      <div className="login_outer">
        <section>
          <div className="container">
            <div className="row loginBox">
              <div className="col-md-6 loginBox_left">
                <img src={login_logo} className="" loading="lazy" alt="" />
                {/* <p className="middle_loginBox">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p> */}
                <p className="footer_loginBox">
                  All Rights Reserved © 2023 Blue Rhine
                </p>
              </div>
              <div className="col-md-6">
                <div className="wrapper_signup">
                  <div className="heading">
                    <h1>Create an Account</h1>
                    <p>Please fill out the form below to get started</p>
                  </div>

                  <form onSubmit={(e) => handleSignUp(e)}>
                    <div className="row name_fild">
                      <div className="col-lg-6">
                        <div className="form-floating mb-3">
                          <TextField
                            id="firstName"
                            label="First name"
                            type="text"
                            defaultValue={userDetails.firstName}
                            onChange={(e) => handleChange(e)}
                            fullWidth
                            variant="outlined"
                            required
                          />
                          {/* <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="First Name"
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                          <label for="floatingInput">First Name</label> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-floating mb-3">
                          <TextField
                            id="lastName"
                            label="Last name"
                            type="text"
                            defaultValue={userDetails.lastName}
                            onChange={(e) => handleChange(e)}
                            fullWidth
                            variant="outlined"
                            required
                          />
                          {/* <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                          <label for="floatingInput">Last Name</label> */}
                        </div>
                      </div>
                    </div>

                    <div className="form-floating mb-3">
                      <TextField
                        id="email"
                        label="Email"
                        type="email"
                        defaultValue={userDetails.email}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                        required
                      />
                      {/* <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <label for="floatingInput">Email</label> */}
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        id="contactNumber"
                        label="Contact Number"
                        type="number"
                        defaultValue={userDetails.contactNumber}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                        required
                      />
                      {/* <input
                        type="number"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                        onChange={(e) => setContact(e.target.value)}
                        required
                      />
                      <label for="floatingInput">Contact Number</label> */}
                    </div>
                    {/* <div className="form-floating mb-3">
                      <TextField
                        id="emirate"
                        label="Emirate"
                        type="text"
                        defaultValue={userDetails.address}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                        required
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        id="city"
                        label="City"
                        type="text"
                        defaultValue={userDetails.city}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                        required
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        id="area"
                        label="Area"
                        type="text"
                        defaultValue={userDetails.area}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        id="street"
                        label="Street"
                        type="text"
                        defaultValue={userDetails.street}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        id="building"
                        label="Building"
                        type="text"
                        defaultValue={userDetails.building}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        id="apartment"
                        label="Apartment"
                        type="text"
                        defaultValue={userDetails.apartment}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                      />
                    </div> */}
                    <div className="form-floating mb-3">
                      <TextField
                        id="address"
                        label="Address"
                        type="text"
                        defaultValue={userDetails.address}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                        helperText="Please make sure to add UAE region address only for shipping address."
                        required
                      />
                    </div>
                    <div className="form-floating mb-3  input-group">
                      <TextField
                        id="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        defaultValue={userDetails.password}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={handleTogglePassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                        required
                      />
                    </div>

                    <div className="form-floating mb-3  input-group">
                      <TextField
                        id="confirmPassword"
                        label="Confirm password"
                        type={showPassword ? "text" : "password"}
                        defaultValue={userDetails.confirmPassword}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={handleTogglePassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                        required
                      />
                      {/* <input
                        type="password"
                        className="form-control"
                        id="floatingInput"
                        placeholder=""
                        onChange={(e) => setconfirmPassword(e.target.value)}
                        required
                      />
                      <label for="floatingInput">Confirm Password</label>
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        className="pw_view_button"
                      >
                        <img
                          src={pw_view_logo}
                          className=""
                          loading="lazy"
                          alt=""
                        />
                      </Button> */}
                    </div>

                    <div className="row qr_fild">
                      <div className="col-sm-5">
                        <div className="mb-3">
                          <div className="qr_code_box">
                            {/* <img
                              src={qr_code_icon}
                              className=""
                              loading="lazy"
                              alt=""
                            /> */}
                            <LoadCanvasTemplate />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <div className="form-floating mb-3 input-group">
                          <TextField
                            label="Captcha"
                            type="text"
                            value={captcha}
                            onChange={(e) => setCaptcha(e.target.value)}
                            fullWidth
                            variant="outlined"
                            required
                          />
                          {/* <input
                            type="Captcha"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Last Name"
                            onChange={(e) => setCaptcha(e.target.value)}
                            required
                          />
                          <label for="floatingInput">Captcha</label> */}
                          {/* <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            className="pw_view_button"
                          >
                            <img
                              src={green_fill_tick}
                              className=""
                              loading="lazy"
                              alt=""
                            />
                          </Button> */}
                        </div>
                      </div>
                    </div>

                    {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Sign Up for Newsletter"
                      />
                    </Form.Group> */}

                    <div className="button_group">
                      <Button
                        variant="primary"
                        className="loginButton_active"
                        type="submit"
                      >
                        Submit
                      </Button>

                      <p>
                        Already have an account?{" "}
                        <Link to="/signin">Sign In!</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
    // </Layout>
  );
};

export default Signup;
