import React, {Component, Fragment } from 'react';

import HeaderCart from '../HeaderCart/HeaderCart';
import FooterCart from '../FooterCart/FooterCart';


    export class LayoutCart extends Component {
        render() {
            return (
                <Fragment>
                    <div className="content-wrapper">
                        <div className="container-fluid1">
                            <div className="card_body">
                                <HeaderCart />
                                    {this.props.children}
                                <FooterCart />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

export default LayoutCart