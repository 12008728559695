import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import instance from "../../helpers/axios";

import LayoutCart from "../LayoutCart/LayoutCart";

import thankYou_tick_icon from "../assets/images/thankYou_tick.svg";

const ThankYou = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const getStatus = async (ref, token) => {
    try {
      const res = await instance.get(`/payment/check/${ref}`);

      // const res = await axios.get(
      //   `https://api-gateway.sandbox.ngenius-payments.com/transactions/outlets/00ca36df-ca62-4274-a000-8dc48dfb0db4/orders/${ref}`,
      //   {
      //     headers: {
      //       PaymentAuthorization: `Bearer ${token}`
      //     },
      //   }
      // );
      if (res.data._embedded.payment[0]["3ds"].status === "FAILURE") {
        Swal.fire({
          icon: "error",
          title: "Payment Failed!",
          text: "Please Try Again",
          timer: 3000,
        }).then(() => navigate("/home"));
      } else {
        const data = {
          reference_id: ref,
          paymentStatus: res.data._embedded.payment[0]["3ds"].status,
          amount: res.data.amount.value / 100,
        };
        const paymentDetails = await instance.post("/payment/addPayment", data);
        const createOrder = await instance.post("/order/create", {
          paymentId: paymentDetails.data.payment_id,
          userId: localStorage.getItem("user-id"),
          shipping_address: localStorage.getItem("shipping_address"),
          payment_method: localStorage.getItem("payment_method"),
          billing_address: localStorage.getItem("billing_address"),
          shipping_method: localStorage.getItem("shipping_method"),
        });
        await instance.put("/order/updateOrderItems", {
          orderId: createOrder.data.order_id,
          userId: localStorage.getItem("user-id"),
        });
        await instance.post(
          `/order/confirm/email/${createOrder.data.order_id}`
        );
        handleClose();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateOrder = async () => {
    try {
      const data = {
        reference_id: null,
        paymentStatus: "pending",
        amount: localStorage.getItem("amount"),
      };
      const paymentDetails = await instance.post("/payment/addPayment", data);
      const createOrder = await instance.post("/order/create", {
        paymentId: paymentDetails.data.payment_id,
        userId: localStorage.getItem("user-id"),
        shipping_address: localStorage.getItem("shipping_address"),
        payment_method: localStorage.getItem("payment_method"),
        billing_address: localStorage.getItem("billing_address"),
        shipping_method: localStorage.getItem("shipping_method"),
      });
      await instance.put("/order/updateOrderItems", {
        orderId: createOrder.data.order_id,
        userId: localStorage.getItem("user-id"),
      });
      await instance.post(`/order/confirm/email/${createOrder.data.order_id}`);
      handleClose();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        timer: 3000,
        icon: "error",
      }).then(() => navigate("/home"));
    }
  };
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get("ref");
    if (ref) {
      getStatus(ref);
    } else {
      updateOrder();
    }
  }, []);
  return (
    <LayoutCart>
      <div className="container thankYouOuter">
        {/* <div className="">
          <Button className="loginButton_active" onClick={handleShow}>
            ThankYou
          </Button>
        </div> */}

        {/* Modal  */}
        {show ? (
          <ThreeDots
            height="100"
            width="100%"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (
          <Modal
            show={!show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            // size="lg"
            className="thankYou_modal "
          >
            {/* <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header> */}
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="icon">
                <img src={thankYou_tick_icon} loading="lazy" alt="" />
              </p>
              <p className="heading">
                Thank You
                <br />
                for your Purchase!
              </p>
              <p className="bodyText">
                For successfully order item, you'll receive a confirmation email
                including order numbers, tracking information and more details.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Link to="/home">
                <Button className="loginButton_active" variant="primary">
                  Go to homepage
                </Button>
              </Link>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </LayoutCart>
  );
};

export default ThankYou;
