import React from "react";
import info_web from "../assets/images/info_web.png";

const Who_it_works = () => {
  return (
    <div className="who_it_works_outer container">
      <h6>How its work </h6>
      <h2>Cutting Your Design has Never been so Easy</h2>
      <img src={info_web} className="home_logo" loading="lazy" alt="" />
    </div>
  );
};

export default Who_it_works;
