import React from "react";

const FooterCart = () => {
  return (
    <div className="footer_inner_outer">
      <div className="container">
        <div className="footer">
          <div className="footer_center">
            All Rights Reserved © 2023 Blue Rhine
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCart;
