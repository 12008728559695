import { createContext, useState } from "react";

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [isAuth, setAuth] = useState(false);
  const [token, setToken] = useState("");
  const [userAuth, setUserAuth] = useState(false);
  const toggleAuth = (token) => {
    if (token) {
      localStorage.setItem("user-token", token);
      localStorage.setItem("isLogin", true);
      setUserAuth(true);
      setToken(token);
      setAuth(true);
    } else {
      setAuth(false);
    }
  };
  const value = { isAuth, token, toggleAuth, userAuth };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
