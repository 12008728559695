import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { UserContext } from "../../context/userContextProvider";
import { AuthContext } from "../../context/authContextProvider";

import home_logo_logo from "../assets/images/home_logo.png";
import hcart_icon from "../assets/images/cart_icon.png";
import user_icon_home from "../assets/images/user_icon_home.png";
import hamburger_icon from "../assets/images/hamburger_home.svg";
import andrew_image from "../assets/images/andrew_image.png";

const Header = () => {
  const { toggleAuth } = useContext(AuthContext);
  const { userDetails } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("user-token");
    toggleAuth();
    navigate("/");
  };

  return (
    <div className="header_box container">
      <div>
        <img src={home_logo_logo} className="home_logo" loading="lazy" alt="" />
      </div>

      <div className="middle_part_home">
        <div className="nav">
          <div className="nav-item">
            <Link to="/home" className="nav-link">
              HOME
            </Link>
          </div>
          <div className="nav-item">
            <Link to="/RigidSubstrates" className="nav-link">
              Rigid Substrates
            </Link>
          </div>
          <div className="nav-item">
            <Link to="/faq" className="nav-link">
              FAQ
            </Link>
          </div>
          <div className="nav-item">
            <a className="nav-link" href="#" onClick={handleLogout}>
              Logout
            </a>
          </div>
        </div>
      </div>
      <div className="right_part">
        <ul>
          <li className="cart_li" onClick={() => navigate("/ShoppingCart")}>
            <img src={hcart_icon} className="" loading="lazy" alt="" />
            {/* <span className="cart_color_circle">2</span> */}
          </li>
          <li>
            <img
              src={user_icon_home}
              className=""
              loading="lazy"
              alt=""
              onClick={() => navigate("/MyProfile")}
            />
          </li>
          <li className="canvas_li_home">
            <button
              className="btn btn-primary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <img src={hamburger_icon} loading="lazy" alt="" />
            </button>

            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel">Offcanvas right</h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul>
                  <li className="profile_imge_text">
                    <img
                      src={andrew_image}
                      className=""
                      loading="lazy"
                      alt=""
                    />
                    <span>
                      Hi,{" "}
                      <span className="name">
                        {userDetails.user && userDetails.user.first_name}
                      </span>
                    </span>
                  </li>
                  <li>HOME</li>
                  <li>Rigid Substrates</li>
                  <li>FAQ</li>
                  <li onClick={handleLogout}>Logout</li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="rigid_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Important Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>We only accept Dxf file.</li>
            <li>
              We follow a mechanism to auto-read your file and auto-estimate, to
              get you an instant quotation, to save you time and help you with
              quick decision.
            </li>
            <li>
              Hence it is important that you upload a file which complies the
              below list of versions/types.
            </li>
            <li>
              Dxf file generated with AutoCAD 2013 DXF, AutoCAD 2010/LT2010 DXF,
              AutoCAD 2007/LT2007 DXF, AutoCAD 2010/LT2004 DXF and AutoCAD
              2010/LT2000 DXF are only supported.
            </li>
            <li>Estimate shall not be accurate for any other versions.</li>
            <li>
              In any case where estimate is not found to be accurate due to
              version change or any other reasons, we shall get back to you by
              email or phone to explain and adjust the estimate, if payment
              already made.
            </li>
            <li>
              Please validate the Linear Meter shown after file upload to ensure
              accuracy before you move on to 'Get A Quote'.
            </li>
            <li>
              Print size in a single cut-file should be in a limit of 2440mm x
              1220mm. If you have any higher size, we recommend you split them
              to multiple cut-files and upload them through multiple
              enquiries/estimate.
            </li>
            <li>
              Please chat with us in WhatsApp or talk to us, for any additional
              support you may require before you submit a file.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="gray_active"
            variant="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button className="loginButton_active" variant="primary">
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;
