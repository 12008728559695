import React, { useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import { UserContext } from "../../context/userContextProvider";
import { AuthContext } from "../../context/authContextProvider";

import inner_logo from "../assets/images/inner_logo.png";
import hcart_icon from "../assets/images/cart_icon.png";
import andrew_image from "../assets/images/andrew_image.png";
import hamburger_icon from "../assets/images/hamburger.svg";
import three_dot_icon from "../assets/images/three_dot.png";
import my_profile_drop_icon from "../assets/images/my_profile_drop.png";
import logout_drop_icon from "../assets/images/logout_drop.png";
import shipping_icon from "../assets/images/shipping_icon.png";

const HeaderInner = () => {
  const { userDetails } = useContext(UserContext);
  const { toggleAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("user-token");
    localStorage.removeItem("isLogin");
    toggleAuth();
    navigate("/");
  };
  const handelClick = () => {
    <Navigate to="/MyProfile" />;
  };

  return (
    <div className="inner_heade_outer">
      <div className="header_box_inner container">
        <div>
          <Link to="/home">
            <img src={inner_logo} className="home_logo" loading="lazy" alt="" />
          </Link>
        </div>

        <div className="middle_part_home">
          <div className="nav">
            <div className="nav-item">
              <Link to="/home" className="nav-link">
                HOME
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/RigidSubstrates" className="nav-link">
                Rigid Substrates
              </Link>
            </div>
            {/* <div className="nav-item">
              <Link to="/myOrder" className="nav-link">
                My Order
              </Link>
            </div> */}
            <div className="nav-item">
              <Link to="/faq" className="nav-link">
                FAQ
              </Link>
            </div>
          </div>
        </div>
        <div className="right_part">
          <ul>
            <li className="cart_li" onClick={() => navigate("/ShoppingCart")}>
              <img src={hcart_icon} className="" loading="lazy" alt="" />
              {/* <span className="cart_color_circle">2</span> */}
            </li>
            <li className="cart_name">
              <img
                src={
                  userDetails && userDetails.user.profile_picture
                    ? userDetails.user.profile_picture
                    : andrew_image
                }
                className="header__user__profile__image"
                loading="lazy"
                alt=""
                // style={{ height: "50px" }}
              />
              <ul className="right_part_name">
                <li>Hi</li>
                <li>{userDetails.user && userDetails.user.first_name}</li>
              </ul>
            </li>
            <li className="three_dot">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img
                    src={three_dot_icon}
                    className="home_logo"
                    loading="lazy"
                    alt=""
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={handelClick}
                    as={Link}
                    to="/MyProfile"
                    className="my_profile"
                  >
                    <img
                      src={my_profile_drop_icon}
                      className="home_logo"
                      loading="lazy"
                      alt=""
                    />{" "}
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    <img
                      src={logout_drop_icon}
                      className="home_logo"
                      loading="lazy"
                      alt=""
                    />{" "}
                    Logout
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => navigate("/myOrder")}>
                    <img
                      src={shipping_icon}
                      className="home_logo"
                      loading="lazy"
                      alt=""
                      style={{
                        height: "25px",
                      }}
                    />{" "}
                    My Order
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="canvas_li_home">
              <button
                className="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <img src={hamburger_icon} loading="lazy" alt="" />
              </button>

              <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header">
                  <h5 id="offcanvasRightLabel">Offcanvas right</h5>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <ul>
                    <li className="profile_imge_text">
                      <img
                        src={andrew_image}
                        className=""
                        loading="lazy"
                        alt=""
                      />
                      <span>
                        Hi, <span className="name">Andrew</span>
                      </span>
                    </li>
                    <li>HOME</li>
                    <li>Rigid Substrates</li>
                    <li>My Order</li>
                    <li>FAQ</li>
                    <li>My Profile</li>
                    <li>Logout</li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderInner;
