import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

const AddressList = ({
  addresses,
  onDelete,
  onEdit,
  billing_address_id,
  handleUpdateBillingAddress,
}) => {
  return (
    <List className="row">
      {addresses?.map((address) => (
        <div className="row" key={address.address_id}>
          <div className="col-12 col-sm-12 col-lg-12">
            <ListItem className="mb-2 border ">
              <ListItemText primary={address.address} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => onEdit(address)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onDelete(address.address_id)}
                >
                  <Delete />
                </IconButton>
                {address.address_id !== billing_address_id && (
                  <Button
                    variant="outlined"
                    sx={{ marginLeft: "5px" }}
                    onClick={() =>
                      handleUpdateBillingAddress(address.address_id)
                    }
                  >
                    Add as billing address
                  </Button>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        </div>
      ))}
    </List>
  );
};

export default AddressList;
